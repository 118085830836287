import React, {useCallback, useEffect, useState} from 'react';
import CoursesListUI from '../view/CoursesListUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import ListCoursesStore from '../store/ListCoursesStore';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {coursesListText} from '../coursesListText';
import {toast} from 'react-toastify';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import NavigationStore from '../../../../navigation/store/NavigationStore';
interface Props {
  listCoursesStore?: ListCoursesStore;
  navigationStore?: NavigationStore;
}

const CoursesListContainer = inject(
  'listCoursesStore',
  'navigationStore',
)(
  observer(({listCoursesStore, navigationStore}: Props) => {
    const [courseToDelete, setCourseToDelete] = useState<string>();
    const [courseToCopy, setCourseToCopy] = useState<string>();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const {organizationId} = useParams();
    const [modalContent, setModalContent] = useState<React.ReactElement | null>(
      null,
    );

    useEffect(() => {
      listCoursesStore!.setCurrentPage(0);
      fetchCourses();
    }, [listCoursesStore, search, organizationId]);

    const onCreateNewCourse = () => {
      navigate(`/manage/${organizationId}/courses/create`);
    };

    const onEdit = (courseId: string) => {
      navigate(`/manage/${organizationId}/courses/${courseId}/general`);
    };

    const fetchCourses = () => {
      if (organizationId) {
        listCoursesStore!.getOrganizationCourses(organizationId, search);
      }
    };

    const onStartDelete = (courseId: string) => {
      setCourseToDelete(courseId);
    };

    const onDelete = () => {
      if (organizationId && courseToDelete && !listCoursesStore!.isLoading) {
        listCoursesStore!
          .deleteCourse(organizationId, courseToDelete)
          .then(success => {
            if (success) {
              toast.success(coursesListText.courseDelted, toastConfig);
            }
            fetchCourses();
            setCourseToDelete(undefined);
          });
      }
    };

    const onOpenFolders = (courseId: string) => {
      navigate(`/editor/${organizationId}/courses/${courseId}/folders`);
    };

    const onCopyCourse = (courseId: string) => {
      setCourseToCopy(courseId);
    };

    const copyCourse = () => {
      if (organizationId && courseToCopy) {
        listCoursesStore!.copyCourse(organizationId, courseToCopy).then(() => {
          fetchCourses();
          setCourseToCopy(undefined);
        });
      }
    };

    const onOpenModal = () => {
      listCoursesStore!.openModal();
    };

    const onClose = () => {
      setModalContent(null);
      listCoursesStore!.closeModal();
    };

    const loadNextPage = useCallback(async () => {
      if (
        listCoursesStore!.currentPage + 1 <= listCoursesStore!.pagesCount &&
        organizationId
      ) {
        listCoursesStore!.setCurrentPage(listCoursesStore!.currentPage + 1);
        listCoursesStore!.fetchNextPage(organizationId, search);
      }
    }, [listCoursesStore!.currentPage, listCoursesStore!.pagesCount]);

    const handleSetSort = (field: string, direction: 'asc' | 'desc') => {
      const isAscending = direction === 'asc';
      listCoursesStore!.setSorting(field, isAscending);
      fetchCourses();
    };

    const onPreview = (courseId: string) => {
      navigate(`/preview/${organizationId}/course/${courseId}`);
    };

    return (
      <div>
        <CoursesListUI
          onCreateNewCourse={onCreateNewCourse}
          onEdit={onEdit}
          onDelete={onStartDelete}
          onOpenFolders={onOpenFolders}
          isLoading={listCoursesStore!.isLoading}
          search={search}
          onPreview={onPreview}
          onCopyCourse={onCopyCourse}
          onSearchChange={setSearch}
          permissions={navigationStore?.userPermissions || []}
          pagesCount={listCoursesStore!.pagesCount}
          onClose={onClose}
          showModal={listCoursesStore!.showModal}
          openModal={onOpenModal}
          modalContent={modalContent}
          onSortChange={handleSetSort}
          sortField={listCoursesStore!.orderBy}
          sortDirection={listCoursesStore!.orderByAsk}
          loadNextPage={loadNextPage}
          courses={listCoursesStore!.courses}
        />
        <ConfirmationModal
          shown={courseToDelete !== undefined}
          onAccept={onDelete}
          onCancel={() => setCourseToDelete(undefined)}
          title={coursesListText.deleteConfirm}
        />
        <ConfirmationModal
          shown={courseToCopy !== undefined}
          onAccept={copyCourse}
          onCancel={() => setCourseToCopy(undefined)}
          title={coursesListText.copyConfirm}
        />
      </div>
    );
  }),
);

export default CoursesListContainer;
