import React, {useState} from 'react';
import {selectSubscriptionText} from '../selectSubscriptionText';

const CREATOR_PRICE = 8.99;
const BUISNESS_PRICE = 7.99;

const BASE_PRICE = 750;

const PlansCalculator = React.memo(() => {
  const [amountOfUsers, setAmountOfUsers] = useState('1');
  const [costPerYear, setCostPerYear] = useState('€ 857.88');

  const onInputChange = (e: any) => {
    if (
      e.target.value.length <= 5 &&
      (/^\d+$/.test(e.target.value) || e.target.value === '')
    ) {
      setAmountOfUsers(e.target.value);
      calculateCost(parseInt(e.target.value, 10));
    }
  };

  const calculateCost = (amountOfUsers: number) => {
    if (!amountOfUsers) {
      setCostPerYear('-');
    } else if (amountOfUsers < 20) {
      setCostPerYear(
        '€ ' + Math.round(amountOfUsers * CREATOR_PRICE * 12 + BASE_PRICE),
      );
    } else if (amountOfUsers < 251) {
      setCostPerYear(
        '€ ' + Math.round(amountOfUsers * BUISNESS_PRICE * 12 + BASE_PRICE),
      );
    } else {
      setCostPerYear('Request quote');
    }
  };

  return (
    <div className="PlansCalculatorContainer">
      <h2 className="SelectTitle">
        {selectSubscriptionText.pricingCalculator}
      </h2>
      <div className="InputsContainer">
        <div>
          <p className="InputTitle d-none d-md-block">
            {selectSubscriptionText.numberOfAdditionalUsers}
          </p>
          <p className="InputTitle d-block d-md-none">
            {selectSubscriptionText.numberOfUsers}
          </p>
          <div className="UsersInput">
            <input value={amountOfUsers} onChange={onInputChange} />
          </div>
        </div>
        <span className="CalculatorEq">=</span>
        <div>
          <p className="InputTitle">{selectSubscriptionText.totalCost}</p>
          <div className="CalculationResult">
            <span>{costPerYear}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlansCalculator;
