import {makeAutoObservable, runInAction} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {CourseListItem} from '../../../../data/models/Course/Course';
import {toast} from 'react-toastify';
import {coursesListText} from '../coursesListText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {getErrorFromCode} from '../../../../data/errorCodes';

export const COURSES_PAGE_SIZE = 15;

class ListCoursesStore {
  private coursesRepo = new CoursesRepository();
  private loadingModule = new LoadingModule();
  private _orderBy = '';
  private _orderByAsk = false;
  private _currentPage = 0;
  private _courses: CourseListItem[] = [];
  private _pagesCount = 0;
  private isOpen = false;

  constructor() {
    makeAutoObservable(this, {
      courses: false,
    });
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get showModal() {
    return this.isOpen;
  }

  public get courses() {
    return this._courses;
  }

  public get currentPage() {
    return this._currentPage;
  }

  public get pagesCount() {
    return this._pagesCount;
  }

  public setCurrentPage(page: number) {
    this._currentPage = page;
  }

  public get orderBy() {
    return this._orderBy;
  }

  public get orderByAsk() {
    return this._orderByAsk;
  }

  public setSorting(orderBy: string, orderByAsk: boolean) {
    runInAction(() => {
      this._orderBy = orderBy;
      this._orderByAsk = orderByAsk;
    });
  }

  public async getOrganizationCourses(organizationId: string, search?: string) {
    this._currentPage = 0;
    const result = await this.coursesRepo.getOrganizationCourses(
      organizationId,
      this._currentPage,
      COURSES_PAGE_SIZE,
      search,
      this._orderBy,
      this._orderByAsk,
    );
    runInAction(() => {
      if (result.success && result.data) {
        const newCourses = result.data.data;
        this._courses = newCourses;
        this._pagesCount = result.data.pagesCount;
      }
    });
  }

  public closeModal() {
    this.isOpen = false;
  }

  public openModal() {
    this.isOpen = true;
  }

  public async deleteCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.coursesRepo.deleteCourse(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    return result.success;
  }

  public async copyCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.coursesRepo.copyCourse(organizationId, courseId);
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(coursesListText.copySuccess, toastConfig);
    } else if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }

  public async fetchNextPage(organizationId: string, search?: string) {
    this.loadingModule.startLoading();
    const result = await this.coursesRepo.getOrganizationCourses(
      organizationId,
      this._currentPage,
      COURSES_PAGE_SIZE,
      search,
    );

    runInAction(() => {
      if (result.success && result.data) {
        this._courses = [
          ...this._courses,
          ...result.data.data.filter(
            newCourse =>
              !this._courses.some(
                existingCourse => existingCourse.id === newCourse.id,
              ),
          ),
        ];
        this._pagesCount = result.data.pagesCount;
      }
    });

    this.loadingModule.endLoading();
  }

  // Método para verificar si un curso es de tipo CourseListItem
  private isCourseListItem(course: any): course is CourseListItem {
    return 'dateCreated' in course && 'organizationId' in course;
  }
}

export default ListCoursesStore;
