import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  title: 'Workspace Dashboard',
  startTrial: 'Start Your Free Trial',
  startTrialInfo:
    "Get started today with a 7-day free trial to explore tapybl's features and create courses at your own pace.",
  startTrialInfo2:
    'Enjoy the flexibility to upgrade or cancel anytime during your trial.',
  startTrialInfo3: 'Ready to elevate your teaching experience?',
  startTrialButton: 'Start Trial',
  contactUsButton: 'Contact Us',
  subcriptionEnded: 'Your Subscription Has Expired',
  subscriptionEndedInfo:
    'Ensure uninterrupted access to your workspace and unlock the complete suite of benefits',
  subscriptionEndedInfo2: 'and features tapybl provides by renewing your plan.',
  clickToSub: 'Simply click on "Renew Now!" to get started.',
  upgradeNow: 'Renew Now!',
  subscriptionExpired:
    'Sorry, it looks like this workspace cannot be accessed right now. Please contact your Administrator.',
  totalLearners: 'Total Learners / Instructors',
  totalCourses: 'Total Published Courses',
  spaceQuota: 'Storage Quota',
  buyMoreSpace: 'Expand Storage',
  aiActionsUsed: 'AI Credits',
  upgradeForMoreActions: 'Add credits',
  avaialbleBalance: 'Available balance',
  exploreAddons: 'Explore add-ons',
  customizeWorkspace: 'Customize Workspace',
  exporeIntegrations: 'Explore Integrations',
  aiAssistants: 'AI assistants',
  manage: 'Manage',
  workspaceInsights: 'Workspace insights',
  userInsights: 'User insights',
  users: 'Users',
  addMoreSeats: 'Add more seats',
  totalUsers: 'Total users',
  learners: 'Learners',
  instructors: 'Instructors',
  available: 'Available',
  creators: 'Creators',
  addCreators: 'Add creators',
  timeSpent: 'Time spent on platform overall (all learners)',
  numberOfLearners: 'Number of learners in the platform (last 6 months)',
  courseInsights: 'Course insights',
  courses: 'Courses',
  created: 'Created',
  published: 'Published',
  avgScore: 'Avg. score',
  courseStatus: 'Course status',
  completedCourses: 'Completions',
  inProgressCourses: 'In progress',
  notStartedCourses: 'Not started',
};

const welsh = {
  title: 'Dangosfwrdd y Gweithle',
  startTrial: 'Dechreuwch Eich Prawf Am Ddim',
  startTrialInfo:
    'Dechreuwch heddiw gyda prawf am ddim am 7 diwrnod i archwilio nodweddion tapybl a chreu cyrsiau yn eich cyflymder eich hun',
  startTrialInfo2:
    'Mwynhewch y hyblygrwydd i wella neu ganslo unrhyw bryd yn ystod eich prawf.',
  startTrialInfo3: 'Yn barod i gynyddu eich profiad dysgu?',
  startTrialButton: 'Dechreuwch y Prawf',
  contactUsButton: 'Cysylltwch â Ni',
  subcriptionEnded: 'Mae eich tanysgrifiad wedi dod i ben',
  subscriptionEndedInfo:
    "Sicrhewch fynediad di-dor i'ch gweithle a datgloi'r holl fantais llawn",
  subscriptionEndedInfo2:
    'a nodweddion y mae tapybl yn eu cynnig trwy adnewyddu eich cynllun',
  clickToSub: "Cliciwch ar 'Adnewyddu Nawr!' i ddechrau",
  upgradeNow: 'Adnewyddu Nawr',
  subscriptionExpired:
    "Mae'n ddrwg gennyf, mae'n edrych fel nad oes modd cyrchu'r gweithle hwn ar hyn o bryd. Cysylltwch â'ch Gweinydduwr os gwelwch yn dda.",
  totalLearners: 'Cyfanswm Dysgwyr / Hyfforddwyr',
  totalCourses: 'Y Cyrsiau Cyhoeddedig Cyfan',
  spaceQuota: 'Cwota Gofod',
  aiActionsUsed: 'Credydau AI ar gael',
  avaialbleBalance: 'Available balance',
  buyMoreSpace: 'Ehangu Storfa',
  upgradeForMoreActions: 'Ail-lwytho credydau',
  upgrade: 'Uwchraddio Nawr',
  customizeWorkspaceBranding: "Addasu Brandio'r Gweithle",
  manageAccount: 'Rheoli Cyfrif',
  manageIntegrations: 'Rheoli Integreiddiadau',
  quickLinks: 'Dolenni Cyflym',
  totalAuthors: 'Creuwyr Cyrsiau',
  totalCreatedCourses: 'Cyfanswm Cyrsiau a Grëwyd',
  buyMoreUsers: 'Ychwanegu Seddau Defnyddiwr',
  getUnlimitedCourses: 'Cewch gyrsiau di-ben-draw',
  workspaceInsights: 'Workspace insights',
  userInsights: 'User insights',
};

export const dashboardText: any = getLocalizationBasedOnLang(english, welsh);
