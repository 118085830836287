import React from 'react';
import {CourseAnalyticsLearnerDetails} from '../../../../data/models/Gradebook/CourseAnalyticsModels';
import {getFirstCapitals} from '../../../../utils/getFirstCapitals';
import {analyticsText} from '../../analyticsText';

interface Props {
  analytics: CourseAnalyticsLearnerDetails;
  onClose: () => void;
  onOpenLearningJourney: (learnerId: string, lessonId: string) => void;
}

const LearnerAnalyticsDetails = React.memo(
  ({analytics, onClose, onOpenLearningJourney}: Props) => {
    return (
      <div className="LearnerAnalyticsDetailsContainer">
        <button className="CloseButton" onClick={onClose}>
          <span className="icon-x-close"></span>
        </button>
        <div className="AvatarContainer">
          {analytics.avatarUrl ? (
            <img src={analytics.avatarUrl} />
          ) : (
            <div className="AvatarPlaceholder">
              {getFirstCapitals(analytics.name)}
            </div>
          )}
          <span className="LearnerName">{analytics.name}</span>
        </div>
        <div className="QuickStatsContainer">
          <div className="QuickStat">
            <p>{analytics.passedLessons}</p>
            <span>{analyticsText.passed}</span>
          </div>
          <div className="QuickStat">
            <p>{analytics.failedLessons}</p>
            <span>{analyticsText.failed}</span>
          </div>
          <div className="QuickStat">
            <p>
              {analytics.avgScore ? Math.round(analytics.avgScore) : 0}
              {analytics.avgScore ? <span>%</span> : null}
            </p>
            <span>{analyticsText.avgScore}</span>
          </div>
        </div>
        <div className="LearnersTableContainer">
          <table>
            <thead>
              <tr>
                <th>{analyticsText.lesson}</th>
                <th>{analyticsText.status}</th>
                <th>{analyticsText.duration}</th>
                <th>{analyticsText.score}</th>
              </tr>
            </thead>
            <tbody>
              {analytics.lessons.map(lesson => (
                <tr
                  key={lesson.id}
                  onClick={() =>
                    onOpenLearningJourney(analytics.learnerId, lesson.id)
                  }>
                  <td>{lesson.name}</td>
                  <td>
                    {lesson.status === 0
                      ? analyticsText.completed
                      : analyticsText.notStarted}
                  </td>
                  <td>00:12:40</td>
                  <td>
                    {lesson.score !== null && lesson.score !== undefined
                      ? Math.round(lesson.score) + '%'
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
);

export default LearnerAnalyticsDetails;
