export const BRANCHING_OPTIONS = [
  {
    key: '1',
    value: 'Enabled',
  },
  {
    key: '0',
    value: 'Disabled',
  },
];

export const ASSESMENT_OPTIONS = [
  {
    key: '1',
    value: 'Enabled',
  },
  {
    key: '0',
    value: 'Disabled',
  },
];
