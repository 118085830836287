import {ApexOptions} from 'apexcharts';
import React, {useState} from 'react';
import Chart from 'react-apexcharts';

const progressOptions: ApexOptions = {
  legend: {
    show: false,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  chart: {
    height: '100%',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '150%',
        labels: {
          total: {
            show: false,
          },
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
  },
  fill: {
    colors: ['#E5E7EA', '#53B483'],
  },
  labels: ['', '', ''],
};

interface Props {
  questionDetails: any;
  index: number;
}

const QuestionDetails = React.memo(({questionDetails, index}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`QuestionDetailsContainer ${
        !isOpen ? 'QuestionDetailsClosed' : ''
      }`}>
      <p className="QuestionDetailsNumber">{index < 9 ? '0' + index : index}</p>
      <div className="QuestionDetailsBody">
        <div className="QuestionDetailsHeader">
          <p className="Title">{questionDetails.question}</p>
          <div className="QuestionDetailsStats">
            <span className="LearnersCount">
              {questionDetails.amountOfLearners}
              <span className="icon-users"></span>
            </span>
            <div className="HeaderDivider" />
            <div className="ProgressChartContainer">
              <Chart
                options={progressOptions}
                series={[
                  100 - questionDetails.learnersPercent,
                  questionDetails.learnersPercent,
                ]}
                type="donut"
                width={40}
                height={40}
              />
              <span className="Amount">{questionDetails.learnersPercent}%</span>
            </div>
            <button
              className={isOpen ? 'ExpandedButton' : 'ExpandButton'}
              onClick={() => setIsOpen(!isOpen)}>
              <span
                className={
                  isOpen ? 'icon-x-close' : 'icon-chevron-down'
                }></span>
            </button>
          </div>
        </div>
        {isOpen && (
          <div>
            {questionDetails.options.map((item: any) => (
              <div
                key={item.id}
                className={`QuestionDetailsOption ${
                  item.isCorrect ? 'CorrectOption' : ''
                }`}>
                <div className="d-flex align-items-center">
                  <div
                    className={`IsCorrectIndicator ${
                      item.isCorrect ? 'Correct' : ''
                    }`}>
                    {item.isCorrect ? (
                      <span className="icon-check"></span>
                    ) : null}
                  </div>
                  <span
                    className="Option"
                    style={
                      item.isCorrect
                        ? {
                            color: '#2F7657',
                          }
                        : undefined
                    }>
                    {item.option}
                  </span>
                </div>
                <div className="QuestionDetailsOptionStats">
                  <span className="Learners">
                    {item.amountOfLearners}
                    <span className="icon-users"></span>
                  </span>
                  <div className="Divider" />
                  <span className="Stat">{item.learnersPercent}%</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default QuestionDetails;
