import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../data/repositories/CoursesRepository';
import {CourseViewModel} from '../../../data/models/Course/Course';
import LoadingModule from '../../../modules/LoadingModule/LoadingModule';

class CreateNewCourseStore {
  private courseRepo = new CoursesRepository();
  private loadingModule = new LoadingModule();

  public errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getLearningStandarts(organizationId: string) {
    const res = await this.courseRepo.getLearingStandarts(organizationId);
    if (res.success && res.data) {
      return res.data;
    }
    return [];
  }

  public async createCourse(model: CourseViewModel, organizationId: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepo.createCourse(model, organizationId);
    this.loadingModule.endLoading();
    if (!result.success && result.errors) {
      this.setErrors(result.errors);
    }
    return result.data;
  }

  public clearErrors() {
    this.errors = [];
  }

  private setErrors(errors: string[]) {
    this.errors = errors;
  }
}

export default CreateNewCourseStore;
