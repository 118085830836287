import {SortOption} from '../../../data/models/UI/SelectOption';

export const WORKSPACE_COURSES_SORT_OPTIONS: SortOption[] = [
  {
    field: 'name',
    order: 'asc',
    title: 'Name (A-Z)',
  },
  {
    field: 'name',
    order: 'desc',
    title: 'Name (Z-A)',
  },
  {
    field: 'date_created',
    order: 'asc',
    title: 'Created Date (Oldest First)',
  },
  {
    field: 'date_created',
    order: 'desc',
    title: 'Created Date (Newest First)',
  },
];

export const WORKSPACE_COURSES_FILTER_OPTIONS: SortOption[] = [
  {
    field: 'name',
    order: 'asc',
    title: 'Published',
  },
  {
    field: 'name',
    order: 'desc',
    title: 'Unpublished',
  },
  {
    field: 'startDate',
    order: 'asc',
    title: 'Private',
  },
  {
    field: 'startDate',
    order: 'desc',
    title: 'Public',
  },
  {
    field: 'startDate',
    order: 'desc',
    title: 'Assessable Courses',
  },
  {
    field: 'startDate',
    order: 'desc',
    title: 'Participatory Courses',
  },
];
