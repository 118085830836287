import React from 'react';
import LessonAnalyticsBranchesUI from '../view/LessonAnalyticsBranchesUI';
import {useNavigate, useParams} from 'react-router-dom';

const LessonAnalyticsBranchesContainer = React.memo(() => {
  const navigate = useNavigate();
  const {organizationId} = useParams();

  const navigateToQuestions = () => {
    navigate(
      `/manage/${organizationId}/analytics/ad7686ac-6c67-43a7-5d74-08dbea953146/lessons/question_details`,
      {replace: true},
    );
  };

  return (
    <LessonAnalyticsBranchesUI navigateToQuestions={navigateToQuestions} />
  );
});

export default LessonAnalyticsBranchesContainer;
