import React from 'react';
import {dashboardText} from '../dashboardText';

const TimeSpentCard = React.memo(() => {
  return (
    <div className="DashboardCard">
      <p className="Title">{dashboardText.timeSpent}</p>
      <div className="CardCenteredBody">
        <h3 className="AiCredits">48h 27m</h3>
      </div>
    </div>
  );
});

export default TimeSpentCard;
