import React, {useState} from 'react';

interface Props {
  branchDetails: any;
}

const BranchDetails = React.memo(({branchDetails}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`BranchDetailsContainer ${
        !isOpen ? 'BranchDetailsClosed' : ''
      }`}>
      <p className="BranchDetailsNumber" />
      <div className="BranchDetailsBody">
        <div className="BranchDetailsHeader">
          <p className="Title">{branchDetails.question}</p>
          <div className="BranchDetailsStats">
            <span className="LearnersCount">
              {branchDetails.amountOfLearners}
              <span className="icon-users"></span>
            </span>
            <div className="HeaderDivider" />
            <button
              className={isOpen ? 'ExpandedButton' : 'ExpandButton'}
              onClick={() => setIsOpen(!isOpen)}>
              <span
                className={
                  isOpen ? 'icon-x-close' : 'icon-chevron-down'
                }></span>
            </button>
          </div>
        </div>
        {isOpen && (
          <div>
            {branchDetails.options.map((item: any) => (
              <div
                key={item.id}
                className={`BranchDetailsOption ${
                  item.isCorrect ? 'CorrectOption' : ''
                }`}>
                <div className="d-flex align-items-center">
                  <span
                    className="Option"
                    style={
                      item.isCorrect
                        ? {
                            color: '#2F7657',
                          }
                        : undefined
                    }>
                    {item.option}
                  </span>
                </div>
                <div className="BranchDetailsOptionStats">
                  <span className="Learners">
                    {item.amountOfLearners}
                    <span className="icon-users"></span>
                  </span>
                  <div className="Divider" />
                  <span className="Stat">{item.learnersPercent}%</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default BranchDetails;
