import React, {useState} from 'react';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {
  CourseAnalyticsLearnerDetails,
  CourseLearnersAnalyticsData,
} from '../../../../data/models/Gradebook/CourseAnalyticsModels';
import OverviewGraph from '../../components/OverviewGraph';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import {analyticsText} from '../../analyticsText';
import AnalyticsCard from '../../components/AnalyticsCard';
import './styles.css';
import AnalyticsEmptyState from '../../components/AnalyticsEmptyState';
import LearnersAnalyticsTable from '../components/LearnersAnalyticsTable';
import Breadcrumbs from '../../../../uiToolkit/Breadcrumbs';
import Button from '../../../../uiToolkit/Buttons/Button';
import {FetchCoursesShortListModel} from '../../../../data/models/AI/AiAssistantModels';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import LearnerAnalyticsDetails from '../components/LearnerAnalyticsDetails';

interface Props {
  courseAnalyticsData?: CourseLearnersAnalyticsData;
  courses: FetchCoursesShortListModel[];
  selectedCourse: string;
  setSelectedCourse: (value: string) => void;
  organizationId?: string;
  onBack: () => void;
  onSelectLearner: (learnerId: string) => void;
  selectedLearnerGradebook?: CourseAnalyticsLearnerDetails;
  onCloseLearnerDetails: () => void;
  onOpenLearningJourney: (learnerId: string, lessonId: string) => void;
}

const breadcrumbsSteps = (organizationId?: string, courseName?: string) => [
  {
    title: analyticsText.analytics,
    link: `/manage/${organizationId}/analytics`,
  },
  {
    title: courseName || '',
  },
];

const CourseAnalyticsLearnersUI = React.memo(
  ({
    courseAnalyticsData,
    selectedCourse,
    setSelectedCourse,
    courses,
    organizationId,
    onBack,
    onSelectLearner,
    selectedLearnerGradebook,
    onCloseLearnerDetails,
    onOpenLearningJourney,
  }: Props) => {
    const [search, setSearch] = useState('');

    const getLearners = () => {
      if (courseAnalyticsData) {
        if (search) {
          return courseAnalyticsData.learners.filter(x =>
            x.name.toLowerCase().includes(search.toLowerCase()),
          );
        } else {
          return courseAnalyticsData.learners;
        }
      }
      return [];
    };

    return (
      <div className="CourseAnalyticsLearnersContainer">
        <div className="HeadingSection">
          <div className="GraphSection">
            <div className="BreadcrumbsContainer">
              <Button
                uiType="text"
                title={analyticsText.back}
                icon="icon-arrow-narrow-left"
                onClick={onBack}
              />
              <Breadcrumbs
                steps={breadcrumbsSteps(
                  organizationId,
                  courseAnalyticsData?.name,
                )}
              />
            </div>
            <PageTitle title={courseAnalyticsData?.name || ''} />
            <OverviewGraph
              completed={courseAnalyticsData?.finishedLearners || 0}
              inProgress={courseAnalyticsData?.inProgressLearners || 0}
              notStarted={courseAnalyticsData?.notStartedLearners || 0}
              label={analyticsText.learners}
            />
          </div>
          <div className="CoursesAndCardsSection">
            <Dropdown
              options={courses.map(course => ({
                key: course.id,
                value: course.title,
              }))}
              selectedOption={selectedCourse}
              setSelectedOption={setSelectedCourse}
              placeholder={analyticsText.selectCourse}
            />
            <div className="AnalyticsCardsContainer">
              <AnalyticsCard
                value={
                  <p>
                    {courseAnalyticsData?.passedAmount
                      ? courseAnalyticsData?.passedAmount
                      : 0}
                  </p>
                }
                label={analyticsText.passed}
              />
              <AnalyticsCard
                value={
                  <p>
                    {courseAnalyticsData?.failedAmount
                      ? courseAnalyticsData?.failedAmount
                      : 0}
                  </p>
                }
                label={analyticsText.failed}
              />
              <AnalyticsCard
                value={
                  <p>
                    0<span className="AnalyticsCardSmallText">h</span>
                    {courseAnalyticsData?.avgScore ? 43 : 0}
                    <span className="AnalyticsCardSmallText">m</span>
                  </p>
                }
                label={analyticsText.avgTime}
              />
              <AnalyticsCard
                value={
                  <p>
                    {courseAnalyticsData?.avgScore &&
                    !isNaN(courseAnalyticsData?.avgScore)
                      ? Math.round(courseAnalyticsData?.avgScore)
                      : 0}
                    <span className="AnalyticsCardSmallText">%</span>
                  </p>
                }
                label={analyticsText.avgScore}
              />
            </div>
          </div>
        </div>
        {!courseAnalyticsData || !courseAnalyticsData.learners.length ? (
          <AnalyticsEmptyState />
        ) : (
          <div>
            <div className="SearchScoreContainer">
              <div className="PassingScoreContainer">
                <span className="icon-icon_score"></span>
                <span className="InfoText">{analyticsText.passingScore}</span>
                <span className="ScoreText">
                  {courseAnalyticsData?.passingScore}
                </span>
              </div>
              <div className="SearchExport">
                <SearchInput value={search} onChange={setSearch} />
                <Button
                  onClick={() => {
                    //TODO
                  }}
                  icon="icon-download_ic"
                  title={analyticsText.export}
                  className="ExportButton"
                />
              </div>
            </div>
            <div className="TableAndDetails">
              <LearnersAnalyticsTable
                learners={getLearners()}
                onSelectLearner={onSelectLearner}
                passingScore={courseAnalyticsData.passingScore}
              />
              {selectedLearnerGradebook && (
                <LearnerAnalyticsDetails
                  analytics={selectedLearnerGradebook}
                  onClose={onCloseLearnerDetails}
                  onOpenLearningJourney={onOpenLearningJourney}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default CourseAnalyticsLearnersUI;
