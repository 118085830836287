export const mockLessonStatCards = [
  {
    title: 'Top performing branch:',
    value: 'Lava Dome',
    amountOfLearners: 6,
    learnersPercent: 66,
    color: '#3B82F6',
  },
  {
    title: 'Best performed question:',
    value: 'What forms when lava cools and hardens after a volcanic eruption?',
    amountOfLearners: 8,
    learnersPercent: 88,
    color: '#53B483',
  },
  {
    title: 'Most challenging question:',
    value: 'How does the shape of a lava dome differ from a typical volcano?',
    amountOfLearners: 5,
    learnersPercent: 55,
    color: '#A32E2E',
  },
];

export const MOCKED_LESSON_BRANCHES = [
  {
    id: '1',
    question: 'Where would you like to start!!',
    amountOfLearners: 9,
    options: [
      {
        id: '1',
        option: 'Lava Dome',
        amountOfLearners: 6,
        learnersPercent: 66,
      },
      {
        id: '2',
        option: 'Composite',
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '3',
        option: 'Cinder Cones',
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '4',
        option: 'Shield',
        amountOfLearners: 1,
        learnersPercent: 11,
      },
    ],
  },
  {
    id: '2',
    question: 'Tap any box to learn more!',
    amountOfLearners: 9,
    options: [
      {
        id: '1',
        option: 'How do lava domes form?',
        amountOfLearners: 7,
        learnersPercent: 77,
      },
      {
        id: '2',
        option: 'Are lava domes dangerous?',
        amountOfLearners: 2,
        learnersPercent: 22,
      },
    ],
  },
  {
    id: '3',
    question: 'Pop Quiz on Lava Domes! Tap the Lava dome below!',
    amountOfLearners: 9,
    learnersPercent: 86,
    options: [
      {
        id: '1',
        option: 'Lava Dome',
        amountOfLearners: 7,
        learnersPercent: 77,
      },
      {
        id: '2',
        option: 'Composite',
        amountOfLearners: 2,
        learnersPercent: 22,
      },
    ],
  },
];
