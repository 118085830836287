import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  title: 'Courses',
  create: 'Create new course',
  actions: 'Actions',
  deleteConfirm: 'Are you sure you want to delete this course?',
  copyConfirm: 'Are you sure you want to copy this course?',
  copySuccess: 'Course copied successfully',
  edit: 'Edit Course Info',
  open: 'Course Editor',
  cantDelete: "You can't delete published course with active enrollments",
  delete: 'Delete',
  published: 'Published',
  yes: 'Yes',
  unpublished: 'Unpublished',
  no: 'No',
  public: 'Public',
  private: 'Private',
  copyCourse: 'Create Copy',
  averageScore: 'Avg. Score',
  courseDeleted: 'Course deleted successfully',
  participatory: 'Participatory',
  learners: 'Learners',
  manageAIAssistant: 'Manage AI Assistant',
  courseEditor: 'Course Editor',
  editInfo: 'Edit Info',
  preview: 'Preview',
  analytics: 'Analytics',
  enrollLearners: 'Enroll Learners',
  assignInstructors: 'Assign instructors',
  copy: 'Copy',
  emptyState1: 'It looks like you haven’t created any courses yet.',
  emptyState2: 'Let’s get started by creating your first course now!',
  viewSyllabus: 'View syllabus',
  noCoursesFound: 'No courses found matching your search term: ',
  noCoursesFound2: 'Please try a different keyword.',
};

const welsh = {
  title: 'Cyrsiau',
  create: 'Creuwch gwrs newydd',
  actions: 'Creuwch gwrs newydd',
  deleteConfirm: "Ydych chi'n siŵr eich bod am ddileu'r cwrs hwn?",
  copyConfirm: "Ydych chi'n siŵr eich bod am gopïo'r cwrs hwn?",
  copySuccess: 'Cwrs wedi’i gopïo’n llwyddiannus',
  edit: 'Golygwch wybodaeth y cwrs',
  open: 'Golygydd Cwrs',
  cantDelete: 'Ni allwch ddileu cwrs cyhoeddedig gyda chofrestriadau actif.',
  delete: 'Dilewch Gwrs',
  published: 'Cyhoeddedig',
  yes: 'Ie',
  unpublished: 'Heb ei gyhoeddi',
  no: 'Na',
  public: 'Cyhoeddus',
  private: 'Preifat',
  copyCourse: 'Creuwch gopi',
  averageScore: 'Avg. Score',
  courseDeleted: 'Cwrs wedi’i ddileu’n llwyddiannus',
  participatory: 'Participatory',
  learners: 'Learners',
  manageAIAssistant: 'Manage AI Assistant',
  courseEditor: 'Course Editor',
  editInfo: 'Edit Info',
  preview: 'Preview',
  analytics: 'Analytics',
  enrollLearners: 'Enroll Learners',
  assignInstructors: 'Assign instructors',
  copy: 'Copy',
};

export const coursesListText: any = getLocalizationBasedOnLang(english, welsh);
