import React from 'react';
import {dashboardText} from '../dashboardText';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  onBuyAddons: () => void;
  creatorsAmount: number;
}

const CreatorsCard = React.memo(({onBuyAddons, creatorsAmount}: Props) => {
  return (
    <div className="DashboardCard">
      <div className="d-flex flex-row justify-content-between">
        <p className="Title">{dashboardText.creators}</p>
        <Button
          uiType="hollow"
          onClick={onBuyAddons}
          title={dashboardText.addCreators}
          icon="icon-icon_plus_circle"
        />
      </div>
      <div className="CardCenteredBody">
        <h3 className="AiCredits">{creatorsAmount}</h3>
      </div>
    </div>
  );
});

export default CreatorsCard;
