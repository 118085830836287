import React, {useEffect, useState} from 'react';
import AnalyticsUI from '../view/AnalyticsUI';
import {inject, observer} from 'mobx-react';
import AnalyticsStore from '../store/AnalyticsStore';
import {useNavigate, useParams} from 'react-router-dom';

interface Props {
  analyticsStore?: AnalyticsStore;
}

const AnalyticsContainer = inject('analyticsStore')(
  observer(({analyticsStore}: Props) => {
    const [search, setSearch] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [orderAsc, setOrderAsc] = useState(true);
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        analyticsStore!.getCoursesList(organizationId);
        analyticsStore!.getLearnersList(organizationId, search, orderAsc);
      }
    }, []);

    useEffect(() => {
      if (selectedCourse) {
        navigate(`${selectedCourse}/learners`);
        setSelectedCourse('');
      }
    }, [selectedCourse]);

    useEffect(() => {
      if (organizationId) {
        analyticsStore!.getLearnersList(organizationId, search, orderAsc);
      }
    }, [search, orderAsc]);

    return (
      <AnalyticsUI
        search={search}
        setSearch={setSearch}
        learners={analyticsStore!.learnersList}
        courses={analyticsStore!.coursesList}
        grades={analyticsStore!.gradesMap}
        coursesAnalytics={analyticsStore!.coursesAnalytics}
        orderAsc={orderAsc}
        setOrderAsc={setOrderAsc}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
      />
    );
  }),
);

export default AnalyticsContainer;
