import {makeAutoObservable} from 'mobx';
import GradebookRepository from '../../../../data/repositories/GradebookRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {AIRepository} from '../../../../data/repositories/AiRepository';

class CourseAnalyticsLearnersStore {
  private gradebookRepo = new GradebookRepository();
  private aiRepository = new AIRepository();

  private leadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public async getCourseLearnersData(organizationId: string, courseId: string) {
    const result = await this.gradebookRepo.getCourseLearnersAnalytics(
      organizationId,
      courseId,
    );
    if (result.success && result.data) {
      return result.data;
    } else if (result.errors && result.errors[0]) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }

  public async getCoursesList(organizationId: string, search: string) {
    this.leadingModule.startLoading();
    const result = await this.aiRepository.fetchCoursesShortList(
      organizationId,
      search,
    );
    this.leadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
    return [];
  }

  public async getCourseLearnerAnalyticsDetails(
    organizationId: string,
    courseId: string,
    learnerId: string,
  ) {
    const result = await this.gradebookRepo.getCourseLearnerAnalyticsDetails(
      organizationId,
      courseId,
      learnerId,
    );
    if (result.success && result.data) {
      return result.data;
    } else if (result.errors && result.errors[0]) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
  }
}

export default CourseAnalyticsLearnersStore;
