import React, {Fragment, useState} from 'react';
import {coursesListText} from '../coursesListText';
import PageTitle from '../../../../uiToolkit/PageTitle';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import Button from '../../../../uiToolkit/Buttons/Button';
import {APP_PERMISSIONS} from '../../../../data/permissions';
import './styles.css';
import {
  worldIcon,
  learnersIcon,
  gradeIcon,
  videoIcon,
  UnpublishedEyeIcon,
  logoTextWhite,
  coursesEmptyState,
} from '../../../../assets/images';
import ModalContainer from '../../../../uiToolkit/ModalContainer';
import SortPill from '../../../../uiToolkit/SortPill/SortPill';
import {CourseListItem} from '../../../../data/models/Course/Course';
import {WORKSPACE_COURSES_SORT_OPTIONS} from '../conf';
import Tooltip from '../../../../uiToolkit/Tooltip';

interface CoursesListUIProps {
  onCreateNewCourse: () => void;
  onClose: () => void;
  openModal: () => void;
  showModal: boolean;
  onEdit: (courseId: string) => void;
  onDelete: (courseId: string) => void;
  onOpenFolders: (courseId: string) => void;
  isLoading: boolean;
  search: string;
  onCopyCourse: (courseId: string) => void;
  onSearchChange: (searchTerm: string) => void;
  permissions: string[];
  pagesCount: number;
  modalContent: React.ReactElement | null;
  loadNextPage: () => Promise<any>;
  courses: CourseListItem[];
  sortField: string;
  sortDirection: boolean;
  onSortChange: (field: string, direction: 'asc' | 'desc') => void;
  onPreview: (courseId: string) => void;
}

const CoursesListUI: React.FC<CoursesListUIProps> = ({
  onCreateNewCourse,
  onEdit,
  onDelete,
  onOpenFolders,
  isLoading,
  search,
  onCopyCourse,
  onSearchChange,
  permissions,
  onClose,
  showModal,
  modalContent,
  loadNextPage,
  courses,
  sortField,
  sortDirection,
  onSortChange,
  onPreview,
}) => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const toggleMenu = (courseId: string) => {
    setActiveMenu(prev => (prev === courseId ? null : courseId));
  };
  // const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const direction = sortDirection ? 'asc' : 'desc';

  const observer = React.useRef<any>();
  const overscrollLock = React.useRef(false);

  const lastPostElementRef = (node: any) => {
    if (isLoading || overscrollLock.current) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        overscrollLock.current = true;
        loadNextPage().then(() => {
          overscrollLock.current = false;
        });
      }
    });

    if (node) observer.current.observe(node);
  };

  return (
    <div className="CoursesListContainer">
      <div className="CoursesListHeader">
        <PageTitle title={coursesListText.title} />
        <div className="CourseFiltersContainer">
          <SearchInput
            value={search}
            onChange={onSearchChange}
            className="Input"
          />
          {permissions.includes(APP_PERMISSIONS.ORG_CREATE_COURSES) && (
            <Button
              title={coursesListText.create}
              onClick={onCreateNewCourse}
              uiType="action"
              icon="icon-log-out"
            />
          )}
        </div>
      </div>
      <div className="CoursesListFiltersContainer">
        <SortPill
          sortField={sortField}
          sortDirection={direction}
          onSetSort={onSortChange}
          options={WORKSPACE_COURSES_SORT_OPTIONS}
        />
      </div>
      {courses.length ? (
        <>
          <ul className="CourseCardContainer">
            {courses.map(course => {
              return (
                <li key={course.id} className="CourseCard">
                  {course.image ? (
                    <div
                      className="courseCardImage"
                      style={{
                        backgroundImage: `url(${course.image})`,
                        backgroundSize: course.image ? 'cover' : '100px',
                      }}
                    />
                  ) : (
                    <div className="courseCardImage">
                      <img src={logoTextWhite} />
                    </div>
                  )}
                  <div className="CategoryContainer">{course.subjectTitle}</div>
                  <div className="courseCardTitleContainer">
                    <Tooltip tooltipText={course.title}>
                      <h1 className="CourseCardTitle">{course.title}</h1>
                    </Tooltip>
                    {course.published ? (
                      <span className="icon-home-line"></span>
                    ) : (
                      <img src={worldIcon} alt="" />
                    )}
                  </div>
                  <div className="courseCardInfo">
                    <div className="courseCardInfoLeftBox">
                      {course.published ? (
                        <>
                          <div>
                            <img src={gradeIcon} alt="" />
                            <p>{course.averageGrade}</p>
                          </div>
                          <span>{coursesListText.averageScore}</span>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                            <img src={videoIcon} alt="" />
                          </div>
                          <span>{coursesListText.participatory}</span>
                        </>
                      )}
                    </div>
                    <div className="spacer">
                      <span></span>
                    </div>
                    <div className="courseCardInfoRightBox">
                      {course.published ? (
                        <>
                          <div>
                            <img src={learnersIcon} alt="" />
                            <p>{course.enrollNumber}</p>
                          </div>
                          <span>{coursesListText.learners}</span>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                            <img src={UnpublishedEyeIcon} alt="" />
                          </div>
                          <span>{coursesListText.unpublished}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <button
                    className="courseCardActions"
                    onClick={() => toggleMenu(course.id)}>
                    <span className="icon-dots-horizontal"></span>
                  </button>
                  {activeMenu === course.id && (
                    <ul className="CourseActionsMenu">
                      <li>
                        <button onClick={() => onOpenFolders(course.id)}>
                          {coursesListText.courseEditor}
                        </button>
                      </li>
                      <li>
                        <button onClick={() => onEdit(course.id)}>
                          {coursesListText.editInfo}
                        </button>
                      </li>
                      <li>
                        <button onClick={() => onPreview(course.id)}>
                          {coursesListText.viewSyllabus}
                        </button>
                      </li>
                      <li>
                        <button onClick={() => onCopyCourse(course.id)}>
                          {coursesListText.copy}
                        </button>
                      </li>
                      {permissions.includes(
                        APP_PERMISSIONS.ORG_DELETE_COURSE,
                      ) && course.enrollNumber === 0 ? (
                        <li>
                          <button onClick={() => onDelete(course.id)}>
                            {coursesListText.delete}
                          </button>
                        </li>
                      ) : (
                        <li>
                          <Tooltip tooltipText={coursesListText.cantDelete}>
                            <button
                              onClick={() => onDelete(course.id)}
                              disabled
                              className="DeleteDisabled">
                              {coursesListText.delete}
                            </button>
                          </Tooltip>
                        </li>
                      )}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div ref={lastPostElementRef} />
        </>
      ) : (
        <div className="CoursesEmptyStateContainer">
          <img src={coursesEmptyState} />
          <p>
            {search !== ''
              ? coursesListText.noCoursesFound + search
              : coursesListText.emptyState1}
          </p>
          <p>
            {search !== ''
              ? coursesListText.noCoursesFound2
              : coursesListText.emptyState2}
          </p>
          {search === '' ? (
            <Button
              onClick={onCreateNewCourse}
              title={coursesListText.create}
              uiType="action"
            />
          ) : null}
        </div>
      )}

      {showModal && (
        <ModalContainer onClose={onClose} hideCloseButton={true}>
          {modalContent || <React.Fragment />}
        </ModalContainer>
      )}
    </div>
  );
};

export default CoursesListUI;
