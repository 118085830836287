import React, {useState} from 'react';
import {
  AvgGradeLearnerListModel,
  AvgLearnerGradeModel,
  CourseWithAvgGradeModel,
} from '../../../../data/models/Gradebook/LearnerAvgGradesModel';
import {analyticsText} from '../../analyticsText';
import Tooltip from '../../../../uiToolkit/Tooltip';
import {getFirstCapitals} from '../../../../utils/getFirstCapitals';

interface Props {
  learners: AvgGradeLearnerListModel[];
  courses: CourseWithAvgGradeModel[];
  grades: {[key: string]: AvgLearnerGradeModel[]};
  orderAsc: boolean;
  setOrderAsc: (value: boolean) => void;
  overallAvgGrade: number;
}

const LearnerGradesTable = React.memo(
  ({
    learners,
    courses,
    grades,
    orderAsc,
    setOrderAsc,
    overallAvgGrade,
  }: Props) => {
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedRow, setSelectedRow] = useState('');

    const clearSelectedRowCol = () => {
      setSelectedColumn('');
      setSelectedRow('');
    };

    const onSelectColumn = (value: string) => (e: any) => {
      e.stopPropagation();
      setSelectedColumn(value);
    };

    const onSelectRow = (value: string) => (e: any) => {
      e.stopPropagation();
      setSelectedRow(value);
    };

    const getGradeByLearnerCourse = (courseId: string, learnerId: string) => {
      if (grades[learnerId]) {
        const record = grades[learnerId].find(x => x.courseId === courseId);
        if (record && !record.isGradable) {
          return <div className="GradeSuccessBox" />;
        } else if (
          record &&
          record.grade !== undefined &&
          record.grade !== null &&
          record.grade >= 0
        ) {
          if (record.grade > record.passingScore) {
            return (
              <div className="GradeSuccessBox">{Math.round(record.grade)}%</div>
            );
          } else {
            return (
              <div className="GradeFailedBox">{Math.round(record.grade)}%</div>
            );
          }
        } else if (record) {
          return <div className="GradeProgressBox" />;
        }
        return <div className="NoResultsDash" />;
      }
      return <div className="NoResultsDash" />;
    };

    const calculateLearnerAvgGrade = (learnerId: string) => {
      const learnerCourses = grades[learnerId];
      if (learnerCourses && learnerCourses.length) {
        let summ = 0;
        let count = 0;
        learnerCourses.forEach(lc => {
          if (lc.grade !== undefined && lc.grade !== null) {
            summ += lc.grade;
            count++;
          }
        });
        if (summ > 0 && count > 0) {
          return Math.round(summ / count) + '%';
        }
        return 0 + '%';
      }
      return '-';
    };

    return (
      <table className="AnalyticsTable" onClick={clearSelectedRowCol}>
        <thead>
          <tr className="GradebookHeader">
            <th
              className="SortHeader"
              onClick={() => {
                setOrderAsc(!orderAsc);
              }}>
              <div className="SortArrowContainer">
                {analyticsText.sortBy}
                <div className={orderAsc ? 'arrow-down' : 'arrow-up'} />
              </div>
            </th>
            <th className="AvgScoreColumn">{analyticsText.avgScoreHeader}</th>
            {courses.map(course => (
              <th
                key={course.id}
                className="LessonHeader"
                onClick={onSelectColumn(course.id)}>
                <Tooltip tooltipText={course.name}>
                  <p>{course.name}</p>
                </Tooltip>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="CourseAvgRow">
            <td className="OverallHeader">{analyticsText.overallScore}</td>
            <td className="AvgScoreColumn AvgSuccess">
              {Math.round(overallAvgGrade)}%
            </td>
            {courses.map(course => (
              <td key={course.id} className="GradeCell">
                {course.avgScore !== null &&
                course.avgScore !== undefined &&
                !isNaN(course.avgScore) ? (
                  <div
                    className={
                      Math.round(course.avgScore) > course.passingScore
                        ? 'GradeSuccessBox'
                        : 'GradeFailedBox'
                    }>
                    {Math.round(course.avgScore)}%
                  </div>
                ) : (
                  <div className="NoResultsDash" />
                )}{' '}
              </td>
            ))}
          </tr>
          {learners.map(learner => (
            <tr
              key={learner.learnerId}
              className={`${
                selectedRow === learner.learnerId ? 'SelectedRowCol' : ''
              }`}>
              <td
                className={'LearnerName'}
                onClick={onSelectRow(learner.learnerId)}>
                <div className="LearnerNameContainer">
                  {learner.avatarUrl ? (
                    <img src={learner.avatarUrl} />
                  ) : (
                    <div className="AvatarPlaceholder">
                      {getFirstCapitals(learner.name)}
                    </div>
                  )}
                  <Tooltip tooltipText={learner.name}>
                    <span>{learner.name}</span>
                  </Tooltip>
                </div>
              </td>
              <td className="AvgScoreColumn AvgSuccess">
                {calculateLearnerAvgGrade(learner.learnerId)}
              </td>
              {courses.map(course => (
                <td
                  key={course.id}
                  className={
                    selectedColumn === course.id ? 'SelectedRowCol' : ''
                  }>
                  {getGradeByLearnerCourse(course.id, learner.learnerId)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  },
);

export default LearnerGradesTable;
