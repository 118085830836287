import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  url: string;
  text: string;
  icon: string;
}

const QuickLink = React.memo(({url, text, icon}: Props) => {
  return (
    <Link to={url} className="QuickLink">
      <span className={`${icon} Icon`}></span>
      <span>{text.split(' ')[0]}</span>
      <span>{text.split(' ')[1]}</span>
    </Link>
  );
});

export default QuickLink;
