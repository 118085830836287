import {makeAutoObservable, runInAction} from 'mobx';
import GradebookRepository from '../../../../data/repositories/GradebookRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';
import {
  AvgGradeLearnerListModel,
  AvgLearnerGradeModel,
  CourseAvgGradesModel,
} from '../../../../data/models/Gradebook/LearnerAvgGradesModel';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {LEARNERS_PER_PAGE} from '../conf';

class AnalyticsStore {
  private gradebookRepo = new GradebookRepository();
  private loadingModule = new LoadingModule();

  private coursesData?: CourseAvgGradesModel;
  private learners: AvgGradeLearnerListModel[] = [];
  private grades: {[key: string]: AvgLearnerGradeModel[]} = {};
  private currentPage = 0;
  private pagesCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public get dataIsReady() {
    return (
      this.coursesData != null &&
      this.coursesData.courses.length > 0 &&
      this.learners.length > 0
    );
  }

  public get coursesList() {
    if (this.coursesData) {
      return this.coursesData.courses;
    }
    return [];
  }

  public get learnersList() {
    return this.learners;
  }

  public get gradesMap() {
    return this.grades;
  }

  public get coursesAnalytics() {
    return this.coursesData;
  }

  public async getCoursesList(organizationId: string) {
    this.loadingModule.startLoading();
    const result = await this.gradebookRepo.getCoursesAvgGrades(organizationId);
    this.loadingModule.endLoading();
    runInAction(() => {
      if (result.success && result.data) {
        this.coursesData = result.data;
      } else if (result.errors && result.errors.length) {
        toast.error(getErrorFromCode(result.errors[0]), toastConfig);
      }
    });
  }

  public async getLearnersList(
    organizationId: string,
    search: string,
    orderAsc: boolean,
  ) {
    this.loadingModule.startLoading();
    this.currentPage = 0;
    const result = await this.gradebookRepo.getLearnersWithAvgScores(
      organizationId,
      this.currentPage,
      LEARNERS_PER_PAGE,
      search,
      orderAsc,
    );
    this.loadingModule.endLoading();
    runInAction(() => {
      if (result.success && result.data) {
        this.learners = result.data.learners;
        this.grades = result.data.grades;
        this.pagesCount = result.data.pagesCount;
      } else if (result.errors && result.errors.length) {
        toast.error(getErrorFromCode(result.errors[0]), toastConfig);
      }
    });
  }

  public async loadNextLearnersPage(
    organizationId: string,
    search: string,
    orderAsc: boolean,
  ) {
    this.loadingModule.startLoading();
    this.currentPage = this.currentPage + 1;

    if (this.currentPage > this.pagesCount) return;

    const result = await this.gradebookRepo.getLearnersWithAvgScores(
      organizationId,
      this.currentPage,
      LEARNERS_PER_PAGE,
      search,
      orderAsc,
    );
    this.loadingModule.endLoading();
    runInAction(() => {
      if (result.success && result.data) {
        this.learners = [...this.learners, ...result.data.learners];
        this.grades = {
          ...this.grades,
          ...result.data.grades,
        };
        this.pagesCount = result.data.pagesCount;
      } else if (result.errors && result.errors.length) {
        toast.error(getErrorFromCode(result.errors[0]), toastConfig);
      }
    });
  }
}

export default AnalyticsStore;
