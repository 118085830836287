import {generateColorPalette} from '../../utils/generatePallete';
import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  PRIMARY_COLORS_PALLETE,
} from '../staticValues/colors';

class ColorsManager {
  public static getPrimaryColor(value: number) {
    const currentPrimaryColor = localStorage.getItem('primaryColor');
    if (currentPrimaryColor === DEFAULT_PRIMARY_COLOR) {
      return PRIMARY_COLORS_PALLETE[value + 1];
    } else if (currentPrimaryColor) {
      const generatedPrimary = generateColorPalette(currentPrimaryColor);
      return generatedPrimary[value + 1];
    }
    return PRIMARY_COLORS_PALLETE[value + 1];
  }

  public static getSecondaryColor() {
    const currentSecondaryColor = localStorage.getItem('secondaryColor');
    if (currentSecondaryColor) {
      return currentSecondaryColor;
    } else {
      return DEFAULT_SECONDARY_COLOR;
    }
  }

  public static getAccentColor() {
    const currentPrimaryColor = localStorage.getItem('primaryColor');
    const currentSecondaryColor = localStorage.getItem('secondaryColor');
    if (
      (currentPrimaryColor === DEFAULT_PRIMARY_COLOR &&
        currentSecondaryColor === DEFAULT_SECONDARY_COLOR) ||
      (!currentPrimaryColor && !currentSecondaryColor)
    ) {
      return DEFAULT_ACCENT_COLOR;
    } else {
      return currentSecondaryColor;
    }
  }
}

export default ColorsManager;
