import React from 'react';
import './styles.css';

interface Props {
  value: any;
  label: string;
}

const AnalyticsCard = React.memo(({value, label}: Props) => {
  return (
    <div className="AnalyticsCard">
      {value}
      <span>{label}</span>
    </div>
  );
});

export default AnalyticsCard;
