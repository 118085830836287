import React from 'react';
import {dashboardText} from '../dashboardText';
import Button from '../../../uiToolkit/Buttons/Button';

interface Props {
  onOpenAssitstants: () => void;
  aiAssistants: number;
}

const AiAssistantsCard = React.memo(
  ({onOpenAssitstants, aiAssistants}: Props) => {
    return (
      <div className="DashboardCard AssistantsCard">
        <div className="d-flex flex-row justify-content-between">
          <p className="Title">{dashboardText.aiAssistants}</p>
          <Button
            uiType="hollow"
            onClick={onOpenAssitstants}
            title={dashboardText.manage}
          />
        </div>
        <div className="CardCenteredBody">
          <h3 className="AiCredits">{aiAssistants}</h3>
        </div>
        <span className="icon-ai_assistant_ic"></span>
      </div>
    );
  },
);

export default AiAssistantsCard;
