import React from 'react';
import './styles.css';

interface Props {
  children: React.ReactElement;
  onClose: () => void;
  hideCloseButton?: boolean;
  style?: any;
}

const ModalContainer = React.memo(
  ({children, onClose, style, hideCloseButton = false}: Props) => {
    return (
      <div className="ModalContainer" style={style}>
        <div className="ModalContent">
          {!hideCloseButton && (
            <button className="CloseButton" onClick={onClose}>
              <span className="icon-x-close"></span>
            </button>
          )}
          {children}
        </div>
      </div>
    );
  },
);

export default ModalContainer;
