import {makeAutoObservable} from 'mobx';
import {MediaFile} from '../../../data/models/LibraryFile/MediaFile';
import LoadingModule from '../../LoadingModule/LoadingModule';
import LessonsRepository from '../../../data/repositories/LessonsRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../data/errorCodes';
import {toastConfig} from '../../../uiToolkit/Toast/toastConfig';
import {aiLessonGeneratorText} from '../aiLessonGeneratorText';

class AILessonGeneratorStore {
  private lessonRepo = new LessonsRepository();

  private order?: number;
  private folderId?: string;
  private courseId?: string;
  private onFinished?: () => void;
  private videoFile?: MediaFile;
  private isOpen = false;
  private isGradable = false;

  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get showModal() {
    return this.isOpen;
  }

  public get sourceFile(): MediaFile | undefined {
    return this.videoFile;
  }

  public set sourceFile(file: MediaFile | undefined) {
    this.videoFile = file;
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public getIsGradable() {
    return this.isGradable;
  }

  public clearSourceFile() {
    this.videoFile = undefined;
  }

  public openWizard(
    videoFile: MediaFile,
    order: number,
    folderId: string,
    courseId: string,
    isGradable: boolean,
    onFinised: () => void,
  ) {
    this.isGradable = isGradable;
    this.order = order;
    this.folderId = folderId;
    this.courseId = courseId;
    this.onFinished = onFinised;
    this.videoFile = videoFile;
    this.isOpen = true;
  }

  public async generateLesson(
    organizationId: string,
    includeBranch: boolean,
    includeGrading: boolean,
    passingScore?: number,
  ) {
    this.loadingModule.startLoading();
    const result = await this.lessonRepo.generateLesson(organizationId, {
      order: this.order || 0,
      folderId: this.folderId || '',
      courseId: this.courseId || '',
      contentId: this.videoFile?.storageFileName || '',
      includeChoiceBranch: includeBranch,
      isGradable: includeGrading,
      passingScore,
    });
    this.loadingModule.endLoading();
    if (result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), {
        ...toastConfig,
        autoClose: 8000,
      });
    } else if (result.success && this.onFinished) {
      if (!includeBranch && !includeGrading) {
        toast.success(aiLessonGeneratorText.lessonIsGenerated, {
          ...toastConfig,
          autoClose: 8000,
        });
      } else {
        toast.success(
          aiLessonGeneratorText.lessonIsBeingGenerated,
          toastConfig,
        );
      }
      this.onFinished();
      this.closeWizard();
    }
  }

  public closeWizard() {
    this.order = undefined;
    this.folderId = undefined;
    this.onFinished = undefined;
    this.isOpen = false;
    this.isGradable = false;
    this.loadingModule.endLoading();
  }
}

export default AILessonGeneratorStore;
