import {
  aiMarketImg,
  instructorsMarkeImg,
  learnersMarketImg,
  scormMarketImg,
  storageMarketImg,
} from '../../../assets/images';
import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';
import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_BUISNESS_PRO,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_CREATOR_TRIAL,
  SUBSCRIPTION_ENTERPRISE,
} from '../config';

export const ADDON_CREDITS = 'addons_ai_credits100k';
export const ADDON_STORAGE = 'addons_storage_10gb';
export const ADDON_LEARNERS = 'addons_learner_or_instructor';
export const ADDON_AUTHORS = 'addons_course_author';

export const marketplaceItems = (tier: number) => [
  {
    image: aiMarketImg,
    text: getLocalizationBasedOnLang(
      'Reload AI Credits to Power Your tapybl Experience with Cutting-Edge AI',
      "Ail-lwythwch Gredydau AI i Bweru'ch Profiad tapybl gyda Deallusrwydd Artiffisial Arloesol",
    ),
    addonId: ADDON_CREDITS,
    buttonText: getLocalizationBasedOnLang('Reload Now', 'Ail-lwythwch Nawr'),
  },
  {
    image: storageMarketImg,
    text: getLocalizationBasedOnLang(
      'Increase Storage to Keep Up with Your Growing Needs',
      "Cynyddwch Storio i Gadw i Fyny â'ch Anghenion sy'n Tyfu",
    ),
    addonId: ADDON_STORAGE,
    buttonText: getLocalizationBasedOnLang(
      'Increase Storage Now',
      'Cynyddwch Storio Nawr',
    ),
  },
  {
    image: learnersMarketImg,
    text: getLocalizationBasedOnLang(
      'Scale as You Go—Add Learners and Instructors as Needed',
      'Ehangwch wrth fynd—Ychwanegwch Ddysgwyr a Hyfforddwyr yn ôl yr angen',
    ),
    addonId:
      tier === SUBSCRIPTION_BUISNESS_PRO || tier === SUBSCRIPTION_ENTERPRISE
        ? undefined
        : ADDON_LEARNERS,
    buttonText: getLocalizationBasedOnLang(
      'Add More Users',
      'Ychwanegwch Mwy o Ddefnyddwyr',
    ),
    quoteLink:
      tier === SUBSCRIPTION_BUISNESS_PRO || tier === SUBSCRIPTION_ENTERPRISE
        ? 'https://help.tapybl.com/support/tickets/new?ticket_form=upgrade_plan'
        : undefined,
  },
  {
    image: scormMarketImg,
    text: getLocalizationBasedOnLang(
      'Integrate SCORM for Seamless Learning Management',
      'Integreiddiwch SCORM ar gyfer Rheoli Dysgu Di-dor',
    ),
    quoteLink:
      'https://help.tapybl.com/support/tickets/new?ticket_form=upgrade_plan',
    buttonText: getLocalizationBasedOnLang(
      'Request a Quote',
      'Gofynnwch am Dyfynbris',
    ),
  },
  {
    image: instructorsMarkeImg,
    text: getLocalizationBasedOnLang(
      'Bring in More Course Creators to Your Workspace',
      "Dewch â Mwy o Greawdwyr Cyrsiau i'ch Gweithle",
    ),
    addonId: tier === SUBSCRIPTION_BUISNESS_BASIC ? ADDON_AUTHORS : undefined,
    buttonText: getLocalizationBasedOnLang(
      'Buy Additional Licenses',
      'Prynwch Drwyddedau Ychwanego',
    ),
    quoteLink:
      tier === SUBSCRIPTION_CREATOR
        ? 'https://help.tapybl.com/support/tickets/new?ticket_form=upgrade_plan'
        : undefined,
  },
];

export const marketplaceCheckOutItems = [
  {
    image: aiMarketImg,
    title: getLocalizationBasedOnLang(
      'AI Credit Top-Up',
      'Ail-lwytho Credydau AI',
    ),
    description: getLocalizationBasedOnLang(
      'Get 100,000 AI credits instantly added to your account, ready to power all AI-driven features and services.',
      "Cafwch 100,000 credyd AI yn cael eu hychwanegu'n syth i'ch cyfrif, yn barod i bweru pob nodwedd a gwasanaeth sy'n cael ei driven gan AI",
    ),
    addonId: 'addons_ai_credits100k',
  },
  {
    image: storageMarketImg,
    title: getLocalizationBasedOnLang('Storage Space', 'Mynediad Storio'),
    description: getLocalizationBasedOnLang(
      'Increase your storage by 10 GB to support the creation and management of more engaging, interactive video experiences.',
      'Cynyddwch eich storio o 10 GB i gefnogi creu a rheoli profiadau fideo mwy deniadol ac rhyngweithiol',
    ),
    addonId: 'addons_storage_10gb',
  },
  {
    image: learnersMarketImg,
    title: getLocalizationBasedOnLang(
      'Add Learners/Instructors',
      'Ychwanegwch Ddysgwyr/Hyfforddwyr',
    ),
    description: getLocalizationBasedOnLang(
      "Scale as You Go—Add Learners and Instructors to expand your team or community. Whether you're growing your learner base or bringing in new instructors, seamlessly scale your educational environment. ",
      "Ehangwch wrth fynd—Ychwanegwch Ddysgwyr a Hyfforddwyr i ehangu eich tîm neu gymuned. P'un a ydych chi'n tyfu eich sylfaen dysgwyr neu'n dod â hyfforddwyr newydd, ehangwch eich amgylchedd addysgol yn ddi-dor",
    ),
    addonId: 'addons_learner_or_instructor',
  },
  {
    image: instructorsMarkeImg,
    title: getLocalizationBasedOnLang(
      'Additional Licenses',
      'Trwyddedau Ychwanego',
    ),
    description: getLocalizationBasedOnLang(
      'Add more Course Creators to your workspace by purchasing additional licenses. Collaborate with more content creators to build a rich, diverse interactive course catalog. ',
      "Ychwanegwch fwy o Greawdwyr Cyrsiau i'ch gweithle trwy brynu trwyddedau ychwanegol. Cydweithredwch â mwy o greuwyr cynnwys i adeiladu catalog cyrsiau rhyngweithiol cyfoethog a divers",
    ),
    addonId: 'addons_course_author',
  },
];

export const getPriceByTierAndAddon = (tier: number, addon: string) => {
  if (tier === SUBSCRIPTION_CREATOR || tier === SUBSCRIPTION_CREATOR_TRIAL) {
    switch (addon) {
      case 'addons_ai_credits100k':
        return 5;
      case 'addons_storage_10gb':
        return 18.5;
      case 'addons_learner_or_instructor':
        return 107.88;
      case 'addons_course_author':
        return -1;
    }
  }
  if (
    tier === SUBSCRIPTION_BUISNESS_BASIC ||
    tier === SUBSCRIPTION_BUISNESS_BASIC_TRIAL
  ) {
    switch (addon) {
      case 'addons_ai_credits100k':
        return 5;
      case 'addons_storage_10gb':
        return 18.5;
      case 'addons_learner_or_instructor':
        return 95.88;
      case 'addons_course_author':
        return 550;
    }
  }
  if (tier === SUBSCRIPTION_BUISNESS_PRO || tier === SUBSCRIPTION_ENTERPRISE) {
    switch (addon) {
      case 'addons_ai_credits100k':
        return 5;
      case 'addons_storage_10gb':
        return 9.25;
    }
  }
};
