import React, {useLayoutEffect, useRef, useState} from 'react';
import {dashboardText} from '../dashboardText';
import {ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import ColorsManager from '../../../data/storage/ColorManager';

const options = (categories: string[]): ApexOptions => ({
  legend: {
    show: false,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '70%',
      borderRadius: 0,
      borderRadiusApplication: 'end',
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: categories,
    labels: {
      show: true,
      style: {
        colors: '#596066',
        fontSize: '12px',
        fontFamily: 'var(--font-family)',
      },
    },
  },
  yaxis: {
    title: {
      text: '',
    },
    labels: {
      show: true,
      style: {
        colors: '#596066',
        fontSize: '12px',
        fontFamily: 'var(--font-family)',
      },
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
  },
  fill: {
    opacity: 1,
    colors: [ColorsManager.getPrimaryColor(8)],
  },
  tooltip: {
    enabled: false,
  },
  grid: {
    show: true,
    borderColor: '#CED2D6',
    strokeDashArray: 3,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
});

interface Props {
  learnersPerMonth: {[key: number]: number};
}

const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const LearnersAmountCard = React.memo(({learnersPerMonth}: Props) => {
  const [cardWidth, setCardWidth] = useState(0);

  const cardRef = useRef<any>(null);
  useLayoutEffect(() => {
    if (cardRef && cardRef.current) {
      setCardWidth(cardRef.current.innerWidth);
    }
  }, []);

  const initDataSeries = () => {
    const currentMonth = new Date().getMonth() + 1;
    const data = [];
    for (let i = 5; i >= 0; i--) {
      const iterationMonth =
        currentMonth - i <= 0 ? currentMonth - i + 12 : currentMonth - i;
      if (learnersPerMonth[iterationMonth]) {
        data.push(learnersPerMonth[iterationMonth]);
      } else {
        data.push(0);
      }
    }
    return data;
  };

  const getCategories = () => {
    const currentMonth = new Date().getMonth();
    const categories = [];
    for (let i = 5; i >= 0; i--) {
      const iterationMonth =
        currentMonth - i < 0 ? currentMonth - i + 12 : currentMonth - i;
      categories.push(month[iterationMonth]);
    }
    return categories;
  };

  return (
    <div className="DashboardCard" ref={cardRef}>
      <p className="Title">{dashboardText.numberOfLearners}</p>
      <div className="LearnersChart">
        <Chart
          options={options(getCategories())}
          series={[
            {
              name: 'Users Per Month',
              data: initDataSeries(),
            },
          ]}
          type="bar"
          width={cardWidth - 40}
        />
      </div>
    </div>
  );
});

export default LearnersAmountCard;
