import React from 'react';
import {dashboardText} from '../dashboardText';

interface Props {
  score: number;
}

const AvgScoreCard = React.memo(({score}: Props) => {
  return (
    <div className="DashboardCard" style={{flex: 0.7}}>
      <p className="Title">{dashboardText.avgScore}</p>
      <div className="CardCenteredBody">
        <p className="AiCredits">
          {score === 0 ? '-' : Math.round(score) + '%'}
        </p>
      </div>
    </div>
  );
});

export default AvgScoreCard;
