import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_CREATOR_TRIAL,
  SUBSCRIPTION_ENTERPRISE,
} from '../config';

export const CHECK_VALUE = 'check';
export const CROSS_VALUE = 'cross';

export const subscriptionPlans = [
  {
    id: SUBSCRIPTION_CREATOR,
    header:
      'Ideal for individuals and small teams building interactive learning.',
    title: 'Creator',
    price: '€ 8.99',
    pricePerYear: '€ 750',
    amountOfUsers: '0 Users (expandable to 19)',
    storage: '5 GB Storage',
    aiCredits: '200,000 AI credits',
    features: [
      'Tapybl AI',
      'Workflow-based lesson builder',
      'Assessable course tools',
      'Slide-to-video generation',
      'Create unlimited courses',
      'Supplemental learning paths',
      'Advanced interactive features',
      'Course analytics',
      'Dynamic challenge branching',
      '1 Creator license (Limit 1 creator per workspace)',
    ],
    buttonText: 'Sign up',
    usersPerMonth: true,
    trialTier: SUBSCRIPTION_CREATOR_TRIAL,
    trialText: 'Start 6-month limited trial',
  },
  {
    id: SUBSCRIPTION_BUISNESS_BASIC,
    header: 'Ideal for growing teams with advanced needs.',
    title: 'Business',
    price: '€ 7.99',
    pricePerYear: '€ 2,668',
    amountOfUsers: '20 Users (expandable to 250)',
    storage: '50 GB Storage',
    aiCredits: '400,000 AI credits',
    features: [
      'Tapybl AI',
      'Workflow-based lesson builder',
      'Assessable course tools',
      'Slide-to-video generation',
      'Create unlimited courses',
      'Supplemental learning paths',
      'Advanced interactive features',
      'Course analytics',
      'Dynamic challenge branching',
      '1 Creator license (Additional licenses can be purchased separately)',
      'White label / customization',
      'Advanced analytics & visual reporting',
      'LTI 1.3 support',
    ],
    buttonText: 'Sign up',
    usersPerMonth: true,
    trialTier: SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
    trialText: 'Start 7-day trial',
  },
  {
    header: 'Custom solutions for all enterprise-level needs.',
    title: 'Enterprise',
    pricePerYear: 'On request',
    amountOfUsers: 'Unlimited users',
    storage: '100 GB Storage',
    aiCredits: '3,000,000 AI credits',
    features: [
      'Tapybl AI',
      'Workflow-based lesson builder',
      'Assessable course tools',
      'Slide-to-video generation',
      'Create unlimited courses',
      'Supplemental learning paths',
      'Advanced interactive features',
      'Course analytics',
      'Dynamic challenge branching',
      'Unlimited creator licenses and users, scalable to meet your needs',
      'White label / customization',
      'Advanced analytics & visual reporting',
      'LTI 1.3 support',
      'Full SCORM support with player integration',
      'Custom data storage & integrations',
      'Personalized account & onboarding support',
    ],
    buttonText: 'Request quote',
    usersPerMonth: false,
    trialText: 'Book demo',
  },
];

export const FeaturesTableConf = {
  subheader: ['Full list of features', 'Creator', 'Business', 'Enterprise'],
  buttons: [
    undefined,
    SUBSCRIPTION_CREATOR,
    SUBSCRIPTION_BUISNESS_BASIC,
    SUBSCRIPTION_ENTERPRISE,
  ],
  sections: [
    {
      title: 'Core features',
      data: [
        ['Unlimited course creation', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        [
          'Lesson builder (Video, Image, Audio)',
          CHECK_VALUE,
          CHECK_VALUE,
          CHECK_VALUE,
        ],
        ['Course analytics', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
      ],
    },
    {
      title: 'Assessable course tools',
      data: [
        ['Multiple choice questions', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Supplemental learning paths', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Challenge branching', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Jump-to nodes', CROSS_VALUE, CHECK_VALUE, CHECK_VALUE],
      ],
    },
    {
      title: 'Tapybl AI',
      data: [
        ['Base AI credits', '200,000', '400,000', '3,000,000'],
        ['Option to add AI credits', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
      ],
    },
    {
      title: 'Data storage',
      data: [
        ['Data storage included', '5GB', '50GB', '100GB'],
        [
          'Option to purchase additional storage',
          CHECK_VALUE,
          CHECK_VALUE,
          CHECK_VALUE,
        ],
      ],
    },
    {
      title: 'Video resolution',
      data: [['Maximum resolution supported', 'HD', 'HD', 'UHD']],
    },
    {
      title: 'Integration',
      data: [
        ['SCORM runtime compatible', '*', CHECK_VALUE, CHECK_VALUE],
        ['LTI support', CROSS_VALUE, CHECK_VALUE, CHECK_VALUE],
        [
          'Content integration (Vimeo, YouTube)',
          CROSS_VALUE,
          CROSS_VALUE,
          CHECK_VALUE,
        ],
        ['SCORM full (including player)', '*', '*', CHECK_VALUE],
      ],
    },
    {
      title: 'Users per package',
      data: [
        ['Total course authors', '1', '1', '5'],
        ['Total admin roles', '1', 'Unlimited', 'Unlimited'],
        ['Users in base package', '1', '20', '1001'],
        ['Maximum users allowed', '19', '250', 'Unlimited'],
      ],
    },
    {
      title: 'Support',
      data: [
        ['Managed help desk', CROSS_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Account & onboarding support', CROSS_VALUE, CROSS_VALUE, CHECK_VALUE],
      ],
    },
  ],
};

export const FAQ = [
  {
    question: 'Can I try tapybl before buying it?',
    answer: [
      'Yes, tapybl offers two trial options based on your needs: a 6-month limited Creator trial, or a 7-day Business trial that provides full access to advanced features, including LTI 1.3 compatibility.',
    ],
  },
  {
    question: 'Do you offer monthly payment plans?',
    answer: ['We currently offer only annual subscription plans.'],
  },
  {
    question: 'What is a User?',
    answer: [
      'In tapybl, a "User" is anyone with access to the platform and can be assigned specific roles within a workspace. Users may be learners or instructors, with tailored permissions based on their role. Learners engage with training programs and courses, while instructors deliver and facilitate these programs. User roles and permissions are managed by those with Admin rights within the workspace.',
    ],
  },
  {
    question: 'What is a Creator?',
    answer: [
      'A "Creator" is a special role in tapybl with permissions to build and manage content. Creators have a dedicated creator license, which includes access to the full course creation suite and other advanced tools. For the Creator package, only one Creator license is available per workspace. However, in Business and Enterprise plans, you can purchase additional Creator licenses as needed. Creators can build courses, adding both learners and instructors to enhance the learning experience.',
    ],
  },
  {
    question: 'What are AI credits?',
    answer: [
      'AI Credits allow you to harness the power of tapybl AI. These credits are used when you utilize AI-powered features, such as generating assessments, transforming content into videos, and creating interactive video lessons. AI credits give you access to the advanced capabilities of our AI assistant, helping streamline your course creation and improve efficiency.',
    ],
  },
  {
    question: 'How many AI credits do I need?',
    answer: [
      'The number of AI Credits needed varies based on the specific content and actions performed, but here are some rough averages to help you estimate:',
      '-	Generating assessments: Typically uses between 1,000 – 5,000 credits on average, depending on the size of the source material.',
      '-	Generating images: AI Image generation uses between 15,000 – 25,000 credits per image, depending on the topic at hand.',
      '-	Transforming content (e.g., PDFs, PowerPoints): Typically uses between 1,000-1,500 credits per slide, though it may be higher based on the size and nature of the content.',
      '-	Creating lessons: Generally, creating lessons uses between 2,000 – 10,000 credits, but this can vary with the depth and the source material involved.',
      "AI Credits are calculated and deducted after each action, so you're only charged for the specific AI-powered tasks you perform.",
    ],
  },
];
