import React from 'react';
import {CourseLearnersAnalyticsItem} from '../../../../data/models/Gradebook/CourseAnalyticsModels';
import {analyticsText} from '../../analyticsText';
import {getFirstCapitals} from '../../../../utils/getFirstCapitals';
import Tooltip from '../../../../uiToolkit/Tooltip';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';

interface Props {
  learners: CourseLearnersAnalyticsItem[];
  passingScore: number;
  onSelectLearner: (learnerId: string) => void;
}

const progressOptions = (status: number): ApexOptions => ({
  legend: {
    show: false,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  chart: {
    height: '100%',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '150%',
        labels: {
          total: {
            show: false,
          },
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
  },
  fill: {
    colors: [
      '#E5E7EA',
      status === 0 ? '#53B483' : status === 1 ? '#3B82F6' : '#E5E7EA',
    ],
  },
  labels: ['', '', ''],
});

const LearnersAnalyticsTable = React.memo(
  ({learners, passingScore, onSelectLearner}: Props) => {
    const getRandomTime = () => {
      const time = Math.round((Math.random() * 100) % 60);
      if (time < 10) {
        return '0' + time;
      }
      return time.toString();
    };
    return (
      <div className="CourseLearnersAnalyticsTableContainer">
        <table className="CourseLearnersAnalyticsTable">
          <thead>
            <tr>
              <th className="LearnerColumn">{analyticsText.learner}</th>
              <th>{analyticsText.status}</th>
              <th>{analyticsText.duration}</th>
              <th>{analyticsText.score}</th>
              <th>{analyticsText.progress}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {learners.map(learner => (
              <tr key={learner.id}>
                <td className="LearnerColumn">
                  <div className="LearnerNameContainer">
                    {learner.avatarUrl ? (
                      <img src={learner.avatarUrl} />
                    ) : (
                      <div className="AvatarPlaceholder">
                        {getFirstCapitals(learner.name)}
                      </div>
                    )}
                    <Tooltip tooltipText={learner.name}>
                      <span>{learner.name}</span>
                    </Tooltip>
                  </div>
                </td>
                <td>
                  {learner.status === 0 ? (
                    <span className="StatusCompleted">
                      {analyticsText.completed}
                    </span>
                  ) : learner.status === 1 ? (
                    <span className="StatusProgress">
                      {analyticsText.inProgress}
                    </span>
                  ) : (
                    <span className="StatusNotStarted">
                      {analyticsText.notStarted}
                    </span>
                  )}
                </td>
                <td className="Duration">
                  00:{getRandomTime()}:{getRandomTime()}
                </td>
                <td
                  className={
                    learner.avgScore || 0 > passingScore
                      ? 'ScoreSuucess'
                      : 'ScoreFail'
                  }>
                  {learner.avgScore != null
                    ? Math.round(learner.avgScore) + '%'
                    : ''}
                </td>
                <td>
                  <div className="ProgressChartContainer">
                    <Chart
                      options={progressOptions(learner.status)}
                      series={[100 - learner.progress, learner.progress]}
                      type="donut"
                      width={40}
                      height={40}
                    />
                    <span className="Amount">{learner.progress}%</span>
                  </div>
                </td>
                <td onClick={() => onSelectLearner(learner.id)}>
                  <span className="icon-eye"></span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  },
);

export default LearnersAnalyticsTable;
