import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import CourseAnalyticsLearnersUI from '../view/CourseAnalyticsLearnersUI';
import CourseAnalyticsLearnersStore from '../store/CourseAnalyticsLearnersStore';
import {useNavigate, useParams} from 'react-router-dom';
import {
  CourseAnalyticsLearnerDetails,
  CourseLearnersAnalyticsData,
} from '../../../../data/models/Gradebook/CourseAnalyticsModels';
import {FetchCoursesShortListModel} from '../../../../data/models/AI/AiAssistantModels';
import LearningJourneyStore from '../../../../modules/LearningJourney/store/LearningJourneyStore';
import {LearningJourney} from '../../../../modules/LearningJourney';

interface Props {
  courseAnalyticsLearnersStore?: CourseAnalyticsLearnersStore;
  learningJourneyStore?: LearningJourneyStore;
}

const CourseAnalyticsLearnersContainer = inject(
  'courseAnalyticsLearnersStore',
  'learningJourneyStore',
)(
  observer(({courseAnalyticsLearnersStore, learningJourneyStore}: Props) => {
    const [selectedCourse, setSelectedCourse] = useState('');
    const [courseData, setCourseData] = useState<CourseLearnersAnalyticsData>();
    const [courses, setCourses] = useState<FetchCoursesShortListModel[]>([]);
    const [selectedLearnerGradebook, setSelectedLearnerGradeboook] =
      useState<CourseAnalyticsLearnerDetails>();
    const {courseId, organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (courseId && organizationId) {
        setSelectedCourse(courseId);
        courseAnalyticsLearnersStore!
          .getCourseLearnersData(organizationId, courseId)
          .then(setCourseData);
        courseAnalyticsLearnersStore!
          .getCoursesList(organizationId, '')
          .then(setCourses);
      }
      return () => {
        setSelectedLearnerGradeboook(undefined);
      };
    }, [courseId]);

    useEffect(() => {
      if (selectedCourse !== courseId && selectedCourse) {
        setSelectedCourse('');
        navigate(
          `/manage/${organizationId}/analytics/${selectedCourse}/learners`,
        );
      }
    }, [selectedCourse]);

    const onBack = () => {
      navigate(`/manage/${organizationId}/analytics/`, {replace: true});
    };

    const onSelectLearner = (learnerId: string) => {
      if (organizationId && courseId) {
        courseAnalyticsLearnersStore!
          .getCourseLearnerAnalyticsDetails(organizationId, courseId, learnerId)
          .then(setSelectedLearnerGradeboook);
      }
    };

    const onCloseLearnerDetails = () => {
      setSelectedLearnerGradeboook(undefined);
    };

    const onOpenLearningJourney = (learnerId: string, lessonId: string) => {
      learningJourneyStore!.openJourney(learnerId, lessonId, courseId || '');
    };

    return (
      <>
        <CourseAnalyticsLearnersUI
          courses={courses}
          courseAnalyticsData={courseData}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
          organizationId={organizationId}
          onBack={onBack}
          onSelectLearner={onSelectLearner}
          selectedLearnerGradebook={selectedLearnerGradebook}
          onCloseLearnerDetails={onCloseLearnerDetails}
          onOpenLearningJourney={onOpenLearningJourney}
        />
        <LearningJourney />
      </>
    );
  }),
);

export default CourseAnalyticsLearnersContainer;
