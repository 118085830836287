import React from 'react';
import './styles.css';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {analyticsText} from '../../analyticsText';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import TableLegend from '../components/TableLegend';
import SearchInput from '../../../../uiToolkit/Inputs/SearchInput';
import Button from '../../../../uiToolkit/Buttons/Button';
import LearnerGradesTable from '../components/LearnerGradesTable';
import {
  AvgGradeLearnerListModel,
  AvgLearnerGradeModel,
  CourseAvgGradesModel,
  CourseWithAvgGradeModel,
} from '../../../../data/models/Gradebook/LearnerAvgGradesModel';
import OverviewGraph from '../../components/OverviewGraph';
import AnalyticsCard from '../../components/AnalyticsCard';
import AnalyticsEmptyState from '../../components/AnalyticsEmptyState';

interface Props {
  search: string;
  setSearch: (value: string) => void;
  learners: AvgGradeLearnerListModel[];
  courses: CourseWithAvgGradeModel[];
  grades: {[key: string]: AvgLearnerGradeModel[]};
  coursesAnalytics?: CourseAvgGradesModel;
  orderAsc: boolean;
  setOrderAsc: (value: boolean) => void;
  setSelectedCourse: (value: string) => void;
  selectedCourse: string;
}

const AnalyticsUI = React.memo(
  ({
    search,
    setSearch,
    learners,
    courses,
    grades,
    coursesAnalytics,
    orderAsc,
    setOrderAsc,
    setSelectedCourse,
    selectedCourse,
  }: Props) => {
    return (
      <div className="AnalyticsContainer">
        <div className="HeadingSection">
          <div className="GraphSection">
            <PageTitle title={analyticsText.title} />
            <OverviewGraph
              completed={coursesAnalytics?.completedCourses || 0}
              inProgress={coursesAnalytics?.inProgressCourses || 0}
              notStarted={coursesAnalytics?.notStartedCourses || 0}
              label={analyticsText.courses}
            />
          </div>
          <div className="CoursesAndCardsSection">
            <Dropdown
              options={courses.map(course => ({
                key: course.id,
                value: course.name,
              }))}
              selectedOption={selectedCourse}
              setSelectedOption={setSelectedCourse}
              placeholder={analyticsText.selectCourse}
            />
            <div className="AnalyticsCardsContainer">
              <AnalyticsCard
                value={
                  <p>
                    {coursesAnalytics?.passedCourses
                      ? coursesAnalytics?.passedCourses
                      : 0}
                  </p>
                }
                label={analyticsText.passed}
              />
              <AnalyticsCard
                value={
                  <p>
                    {coursesAnalytics?.failedCourses
                      ? coursesAnalytics?.failedCourses
                      : 0}
                  </p>
                }
                label={analyticsText.failed}
              />
              <AnalyticsCard
                value={
                  <p>
                    0<span className="AnalyticsCardSmallText">h</span>43
                    <span className="AnalyticsCardSmallText">m</span>
                  </p>
                }
                label={analyticsText.avgTime}
              />
              <AnalyticsCard
                value={
                  <p>
                    {coursesAnalytics?.globalAvgScore &&
                    !isNaN(coursesAnalytics?.globalAvgScore)
                      ? Math.round(coursesAnalytics?.globalAvgScore)
                      : 0}
                    <span className="AnalyticsCardSmallText">%</span>
                  </p>
                }
                label={analyticsText.avgScore}
              />
            </div>
          </div>
        </div>
        <div className="TableLegendAndSearch">
          <TableLegend />
          <div className="SearchExport">
            <SearchInput value={search} onChange={setSearch} />
            <Button
              onClick={() => {
                //TODO
              }}
              icon="icon-download_ic"
              title={analyticsText.export}
              className="ExportButton"
            />
          </div>
        </div>
        {learners.length > 0 && courses.length > 0 ? (
          <div className="AnalyticsTableContainer">
            <LearnerGradesTable
              learners={learners}
              courses={courses}
              grades={grades}
              orderAsc={orderAsc}
              setOrderAsc={setOrderAsc}
              overallAvgGrade={
                coursesAnalytics?.globalAvgScore &&
                !isNaN(coursesAnalytics?.globalAvgScore)
                  ? Math.round(coursesAnalytics?.globalAvgScore)
                  : 0
              }
            />
          </div>
        ) : (
          <AnalyticsEmptyState />
        )}
      </div>
    );
  },
);

export default AnalyticsUI;
