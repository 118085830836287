import React from 'react';
import {analyticsText} from '../../analyticsText';

const TableLegend = () => {
  return (
    <div className="TableLegend">
      <div className="TableLegendItem">
        <div className="Completed" />
        <span>{analyticsText.completed}</span>
      </div>
      <div className="TableLegendItem">
        <div className="Failed" />
        <span>{analyticsText.failed}</span>
      </div>
      <div className="TableLegendItem">
        <div className="Progress" />
        <span>{analyticsText.inProgress}</span>
      </div>
      <div className="TableLegendItem">
        <div className="Unassigned">
          <div className="UnassignedDash" />
        </div>
        <span>{analyticsText.unassigned}</span>
      </div>
    </div>
  );
};

export default TableLegend;
