import React from 'react';
import {dashboardText} from '../dashboardText';
import PageTitle from '../../../uiToolkit/PageTitle';
import './styles.css';
import {DashboardData} from '../../../data/models/Workspace/Dashboard';
import NoSubscriptionState from '../components/NoSubscriptionState';
import AiCreditsCard from '../components/AiCreditsCard';
import StorageCard from '../components/StorageCard';
import QuickLink from '../components/QuickLink';
import AiAssistantsCard from '../components/AiAssistantsCard';
import UsersCard from '../components/UsersCard';
import CreatorsCard from '../components/CreatorsCard';
import TimeSpentCard from '../components/TimeSpentCard';
import LearnersAmountCard from '../components/LearnersAmountCard';
import CoursesCard from '../components/CoursesCard';
import AvgScoreCard from '../components/AvgScoreCard';
import CourseProgressCard from '../components/CourseProgressCard';

interface Props {
  dashboardData?: DashboardData;
  onSubscribe: () => void;
  onStartTrial: () => void;
  navigateCustomizeWorkspaceBranding: () => void;
  navigateManageAccount: () => void;
  navigateManageIntegrations: () => void;
  hasSubscription: boolean;
  usedTrial: boolean;
  isLoading: boolean;
  hasAdminPermissions: boolean;
  subscriptionPermissions: any;
  onContactUs: () => void;
  onBuyAddons: () => void;
  organizationId?: string;
  onOpenAssistants: () => void;
}

const WorkspaceUI = React.memo(
  ({
    dashboardData,
    onSubscribe,
    hasAdminPermissions,
    hasSubscription,
    isLoading,
    onBuyAddons,
    organizationId,
    onOpenAssistants,
  }: Props) => {
    if (!hasSubscription) {
      return (
        <NoSubscriptionState
          hasAdminPermissions={hasAdminPermissions}
          isLoading={isLoading}
          onSubscribe={onSubscribe}
        />
      );
    }

    if (!dashboardData) {
      return (
        <div className="WorkspaceContainer">
          <PageTitle title={dashboardText.title} />
        </div>
      );
    }

    return (
      <div className="WorkspaceContainer">
        <PageTitle title={dashboardText.title} />
        <p className="SectionHeader">{dashboardText.workspaceInsights}</p>

        <div className="Section">
          <AiCreditsCard
            onBuyAddons={onBuyAddons}
            creditsAvailable={dashboardData.aiCreditsAvailable}
          />
          <StorageCard
            onBuyAddons={onBuyAddons}
            storageAvailable={dashboardData.maxStorageSpaceAvailable}
            storageUsed={dashboardData.storageSpaceUsed}
          />
          <div className="LinksAndAssistants">
            <div className="QuickLinksContainer">
              <QuickLink
                url={`/${organizationId}/marketplace`}
                text={dashboardText.exploreAddons}
                icon="icon-icon_marketplace"
              />
              <QuickLink
                url={`/manage/${organizationId}/settings/general`}
                text={dashboardText.customizeWorkspace}
                icon="icon-icon_customize"
              />
              <QuickLink
                url={`/manage/${organizationId}/settings/lti_settings`}
                text={dashboardText.exporeIntegrations}
                icon="icon-icon_settings"
              />
            </div>
            <AiAssistantsCard
              onOpenAssitstants={onOpenAssistants}
              aiAssistants={dashboardData.aiAssistantsNumber}
            />
          </div>
        </div>

        <p className="SectionHeader">{dashboardText.userInsights}</p>

        <div className="Section">
          <UsersCard
            leaners={dashboardData.learnerNumbers}
            instructors={dashboardData.instructorNumbers}
            availalbe={dashboardData.maxLearnersAvailable}
            onBuyAddons={onBuyAddons}
          />
          <div className="CreatorsAndTime">
            <CreatorsCard
              onBuyAddons={onBuyAddons}
              creatorsAmount={dashboardData.amountOfCreators}
            />
            <TimeSpentCard />
          </div>
          <LearnersAmountCard
            learnersPerMonth={dashboardData.learnersPerMonth}
          />
        </div>

        <p className="SectionHeader">{dashboardText.courseInsights}</p>

        <div className="Section">
          <div className="CoursesScore">
            <CoursesCard
              allCourses={dashboardData.draftCoursesNumbers}
              publishedCourses={dashboardData.courseNumbers}
            />
            <AvgScoreCard score={dashboardData.avgCoursesScore} />
          </div>
          <CourseProgressCard
            completedCourses={dashboardData.completedCourses}
            inProgressCourses={dashboardData.inProgressCourses}
            notStartedCourses={dashboardData.notStartedCourses}
          />
        </div>
      </div>
    );
  },
);

export default WorkspaceUI;
