export const DEFAULT_PRIMARY_COLOR = '#141E30';
export const DEFAULT_SECONDARY_COLOR = '#2B8982';
export const DEFAULT_ACCENT_COLOR = '#B8EF36';

export const PRIMARY_COLORS_PALLETE = [
  '#141E30',
  '#24334D',
  '#384A6A',
  '#4F6387',
  '#6A7FA5',
  '#879CC2',
  '#A8BCDF',
  '#CCDDFC',
  '#DDE9FF',
  '#EBF2FF',
  '#FAFCFF',
];
