import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const englishAiGenText = {
  title: 'Generate lesson with tapybl AI',
  customizeLesson: 'Customize lesson',
  includeBranching: 'Branching',
  branchingSubtitle:
    'Enable multiple learning paths for personalized experiences.',
  includeGrading: 'Assessment',
  assesmentSubtitle: 'Enable assessment to evaluate learner understanding.',
  passingScore: 'Passing Score',
  passingScoreSubtitle: 'Set the minimum passing score (0-100)',
  generate: 'Generate lesson',
  cancel: 'Cancel',
  source: 'Source:',
  lessonIsBeingGenerated:
    'Lesson generation can take up to 5 minutes. Please refresh this page to see updates.',
  lessonIsGenerated: 'Your lesson is generated',
};

export const welshAiGenText = {
  title: 'Cynhyrchwch Gwers gan ddefnyddio Cynorthwy-ydd AI',
  includeBranching: 'Cynnwys Canghennog?',
  includeGrading: 'Cynnwys Graddio?',
  passingScore: "Sgôr i'r Basio",
  generate: 'Cynhyrchu',
  source: 'Ffynhonnell',
  lessonIsBeingGenerated:
    'Gall cynhyrchu gwersi gymryd hyd at bum munud. Adnewyddwch y dudalen hon i weld diweddariadau.',
  lessonIsGenerated: 'Cynhyrchir eich gwers',
};

export const aiLessonGeneratorText: any = getLocalizationBasedOnLang(
  englishAiGenText,
  welshAiGenText,
);
