import React from 'react';
import {dashboardText} from '../dashboardText';
import {truncateNumberTo2Decimals} from '../../../utils/getTimeTextFromSeconds';

interface Props {
  completedCourses: number;
  inProgressCourses: number;
  notStartedCourses: number;
}

const MAX_HEIGHT = 145;

const CourseProgressCard = React.memo(
  ({completedCourses, inProgressCourses, notStartedCourses}: Props) => {
    const coursesSumm = () =>
      completedCourses + inProgressCourses + notStartedCourses;

    const getCompletedCoursesStyle = () => {
      return {
        height: `${(completedCourses * MAX_HEIGHT) / coursesSumm() + 20}px`,
        backgroundColor: 'var(--accent-color)',
      };
    };

    const getInProgressCoursesStyle = () => {
      return {
        height: `${(inProgressCourses * MAX_HEIGHT) / coursesSumm() + 20}px`,
        backgroundColor: 'var(--primary-color-3)',
      };
    };

    const getNotStartedCoursesStyle = () => {
      return {
        height: `${(notStartedCourses * MAX_HEIGHT) / coursesSumm() + 20}px`,
        backgroundColor: 'var(--primary-color-8)',
      };
    };

    return (
      <div className="DashboardCard CourseProgressCard">
        <p className="Title">{dashboardText.courseStatus}</p>
        <div className="CardCenteredBody">
          <div className="CourseStatusGraphContainer">
            <div className="CourseStatusVerticalLine" />
            <div
              className="CourseStatusProgressBlock"
              style={getCompletedCoursesStyle()}
            />
            <div className="CourseStatusVerticalLine" />
            <div
              className="CourseStatusProgressBlock"
              style={getInProgressCoursesStyle()}
            />
            <div className="CourseStatusVerticalLine" />
            <div
              className="CourseStatusProgressBlock"
              style={getNotStartedCoursesStyle()}
            />
            <div className="CoursePercentage">
              <span className="CoursePercentageIndicator">
                {coursesSumm() === 0
                  ? 0
                  : truncateNumberTo2Decimals(
                      (completedCourses * 100) / coursesSumm(),
                    )}
                %
              </span>
              <span
                className="CoursePercentageIndicator"
                style={{color: 'white'}}>
                {coursesSumm() === 0
                  ? 0
                  : truncateNumberTo2Decimals(
                      (inProgressCourses * 100) / coursesSumm(),
                    )}
                %
              </span>
              <span className="CoursePercentageIndicator">
                {coursesSumm() === 0
                  ? 0
                  : truncateNumberTo2Decimals(
                      (notStartedCourses * 100) / coursesSumm(),
                    )}
                %
              </span>
            </div>
            <div className="CourseAmount">
              <span className="CourseAmountIndicator">
                {isNaN(completedCourses) ? 0 : completedCourses}
                <br />
                {dashboardText.courses}
              </span>
              <span className="CourseAmountIndicator">
                {isNaN(inProgressCourses) ? 0 : inProgressCourses}
                <br />
                {dashboardText.courses}
              </span>
              <span className="CourseAmountIndicator">
                {isNaN(notStartedCourses) ? 0 : notStartedCourses}
                <br />
                {dashboardText.courses}
              </span>
            </div>
            <div
              className="CourseIndicator"
              style={{
                top: '-6px',
                left: '-5px',
                backgroundColor: 'var(--accent-color)',
              }}
            />
            <div
              className="CourseIndicator"
              style={{
                top: '-6px',
                left: '105px',
                backgroundColor: 'var(--primary-color-3)',
              }}
            />
            <div
              className="CourseIndicator"
              style={{
                top: '-6px',
                left: '215px',
                backgroundColor: 'var(--primary-color-8)',
              }}
            />
            <div className="CourseLabels">
              <span className="CourseAmountIndicator">
                {dashboardText.completedCourses}
              </span>
              <span className="CourseAmountIndicator">
                {dashboardText.inProgressCourses}
              </span>
              <span className="CourseAmountIndicator">
                {dashboardText.notStartedCourses}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default CourseProgressCard;
