import React from 'react';
import {dashboardText} from '../dashboardText';
import Button from '../../../uiToolkit/Buttons/Button';
import {ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import ColorsManager from '../../../data/storage/ColorManager';

interface Props {
  leaners: number;
  instructors: number;
  availalbe: number;
  onBuyAddons: () => void;
}

const options = (isEmpty: boolean): ApexOptions => ({
  legend: {
    show: false,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        labels: {
          total: {
            show: false,
          },
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
  },
  fill: {
    colors: isEmpty
      ? ['#CED2D6']
      : [
          ColorsManager.getPrimaryColor(3),
          ColorsManager.getAccentColor(),
          ColorsManager.getPrimaryColor(8),
        ],
  },
  stroke: {
    lineCap: 'butt',
    width: 8,
  },
  labels: ['', '', ''],
});

const UsersCard = React.memo(
  ({onBuyAddons, leaners, instructors, availalbe}: Props) => {
    return (
      <div className="DashboardCard">
        <div className="d-flex flex-row justify-content-between">
          <p className="Title">{dashboardText.users}</p>
          <Button
            uiType="hollow"
            onClick={onBuyAddons}
            title={dashboardText.addMoreSeats}
            icon="icon-icon_plus_circle"
          />
        </div>
        <div className="CardCenteredBody">
          <div className="UsersChart">
            <Chart
              options={options(availalbe === 0)}
              series={[
                leaners,
                instructors,
                availalbe ? availalbe - leaners - instructors : 1,
              ]}
              type="donut"
              width={300}
              height={220}
            />
            <div className="TotalUsers">
              <span>{dashboardText.totalUsers}</span>
              <p>{leaners + instructors}</p>
            </div>
          </div>
        </div>
        <div className="UsersLegend">
          <table>
            <tr className="LegendRow">
              <td>
                <div
                  className="LegendCircle"
                  style={{backgroundColor: 'var(--primary-color-3)'}}
                />
              </td>
              <td className="LegendTitle">
                <span>{dashboardText.learners}</span>
              </td>
              <td className="LegendValue CountValue">
                <span>{leaners}</span>
              </td>
              <td className="LegendValue">
                <span>
                  {availalbe ? Math.round((leaners * 100) / availalbe) : 0}%
                </span>
              </td>
            </tr>
            <tr className="LegendRow">
              <td>
                <div
                  className="LegendCircle"
                  style={{backgroundColor: 'var(--accent-color)'}}
                />
              </td>
              <td className="LegendTitle">
                <span>{dashboardText.instructors}</span>
              </td>
              <td className="LegendValue CountValue">
                <span>{instructors}</span>
              </td>
              <td className="LegendValue">
                <span>
                  {availalbe ? Math.round((instructors * 100) / availalbe) : 0}%
                </span>
              </td>
            </tr>
            <tr className="LegendRow">
              <td>
                <div
                  className="LegendCircle"
                  style={{backgroundColor: 'var(--primary-color-8)'}}
                />
              </td>
              <td className="LegendTitle">
                <span>{dashboardText.available}</span>
              </td>
              <td className="LegendValue CountValue">
                <span>{availalbe - leaners - instructors}</span>
              </td>
              <td className="LegendValue">
                <span>
                  {availalbe
                    ? Math.round(
                        ((availalbe - leaners - instructors) * 100) / availalbe,
                      )
                    : 0}
                  %
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  },
);

export default UsersCard;
