import React from 'react';
import LessonAnalyticsQuestionsUI from '../view/LessonAnalyticsQuestionsUI';
import {useNavigate, useParams} from 'react-router-dom';

const LessonAnalyticsQuestionsContainer = React.memo(() => {
  const navigate = useNavigate();
  const {organizationId} = useParams();

  const navigateToBranches = () => {
    navigate(
      `/manage/${organizationId}/analytics/ad7686ac-6c67-43a7-5d74-08dbea953146/lessons/branches_details`,
      {replace: true},
    );
  };

  return <LessonAnalyticsQuestionsUI navigateToBranches={navigateToBranches} />;
});

export default LessonAnalyticsQuestionsContainer;
