import GradebookAPI from '../api/GradebookAPI';

class GradebookRepository {
  private gradebookAPI = new GradebookAPI();

  public getCourseGradebook(
    organizationId: string,
    courseId: string,
    page: number,
    pageSize: number,
    search: string,
  ) {
    return this.gradebookAPI.getCourseGradebook(
      organizationId,
      courseId,
      page,
      pageSize,
      search,
    );
  }

  public getLearningJourney(
    learnerId: string,
    lessonId: string,
    courseId: string,
  ) {
    return this.gradebookAPI.getLearnerProgressDetails(
      courseId,
      lessonId,
      learnerId,
    );
  }

  public async getCoursesAvgGrades(organizationId: string) {
    return this.gradebookAPI.getCoursesAvgGrades(organizationId);
  }

  public async getLearnersWithAvgScores(
    organizationId: string,
    page: number,
    pageSize: number,
    search: string,
    orderAsc: boolean,
  ) {
    return this.gradebookAPI.getLearnersWithAvgScores(
      organizationId,
      page,
      pageSize,
      search,
      orderAsc,
    );
  }

  public async getCourseLearnersAnalytics(
    organizationId: string,
    courseId: string,
  ) {
    return this.gradebookAPI.getCourseLearnersAnalytics(
      organizationId,
      courseId,
    );
  }

  public async getCourseLearnerAnalyticsDetails(
    organizationId: string,
    courseId: string,
    learnerId: string,
  ) {
    return this.gradebookAPI.getCourseLearnerAnalyticsDetails(
      organizationId,
      courseId,
      learnerId,
    );
  }
}

export default GradebookRepository;
