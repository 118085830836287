import React from 'react';
import {dashboardText} from '../dashboardText';
import Button from '../../../uiToolkit/Buttons/Button';
import {numberWithCommas} from '../../../utils/getFirstCapitals';

interface Props {
  onBuyAddons: () => void;
  creditsAvailable: number;
}

const AiCreditsCard = React.memo(({onBuyAddons, creditsAvailable}: Props) => {
  return (
    <div className="DashboardCard">
      <p className="Title">{dashboardText.aiActionsUsed}</p>
      <div className="CardCenteredBody">
        <span className="AvailableBalance">
          {dashboardText.avaialbleBalance}
        </span>
        <h3 className="AiCredits">
          {numberWithCommas(creditsAvailable.toString())}
        </h3>
      </div>
      <div className="d-flex flex-row justify-content-center">
        <Button
          uiType="hollow"
          title={dashboardText.upgradeForMoreActions}
          onClick={onBuyAddons}
          icon="icon-icon_plus_circle"
        />
      </div>
    </div>
  );
});

export default AiCreditsCard;
