export const mockLessonStatCards = [
  {
    title: 'Top performing branch:',
    value: 'Lava Dome',
    amountOfLearners: 6,
    learnersPercent: 66,
    color: '#3B82F6',
  },
  {
    title: 'Best performed question:',
    value: 'What forms when lava cools and hardens after a volcanic eruption?',
    amountOfLearners: 8,
    learnersPercent: 88,
    color: '#53B483',
  },
  {
    title: 'Most challenging question:',
    value: 'How does the shape of a lava dome differ from a typical volcano?',
    amountOfLearners: 5,
    learnersPercent: 55,
    color: '#A32E2E',
  },
];

export const MOCKED_LESSON_QUESTIONS = [
  {
    id: '1',
    question:
      'How does the shape of a lava dome differ from a typical volcano?',
    amountOfLearners: 9,
    learnersPercent: 44,
    options: [
      {
        id: '1',
        option: 'Lava domes are flat and wide.',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '2',
        option: 'Lava domes are steep and dome-shaped.',
        isCorrect: true,
        amountOfLearners: 4,
        learnersPercent: 44,
      },
      {
        id: '3',
        option: 'Lava domes have a crater at the top.',
        isCorrect: false,
        amountOfLearners: 3,
        learnersPercent: 33,
      },
      {
        id: '4',
        option: 'Lava domes are cone-shaped with a peak.',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
    ],
  },
  {
    id: '2',
    question:
      'What is the main opening through which magma escapes from a volcano?',
    amountOfLearners: 9,
    learnersPercent: 77,
    options: [
      {
        id: '1',
        option: 'Crater',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '2',
        option: 'Vent',
        isCorrect: true,
        amountOfLearners: 8,
        learnersPercent: 88,
      },
      {
        id: '3',
        option: 'Lava Dome.',
        isCorrect: false,
        amountOfLearners: 0,
        learnersPercent: 0,
      },
      {
        id: '4',
        option: 'Parasitic Cone.',
        isCorrect: false,
        amountOfLearners: 0,
        learnersPercent: 0,
      },
    ],
  },
  {
    id: '3',
    question:
      'What forms when lava cools and hardens after a volcanic eruption?',
    amountOfLearners: 9,
    learnersPercent: 88,
    options: [
      {
        id: '1',
        option: 'Magma Chamber',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '2',
        option: 'Ash Cloud',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '3',
        option: 'Lava Flow',
        isCorrect: true,
        amountOfLearners: 7,
        learnersPercent: 77,
      },
      {
        id: '4',
        option: 'Sill',
        isCorrect: false,
        amountOfLearners: 0,
        learnersPercent: 0,
      },
    ],
  },
  {
    id: '4',
    question:
      'Which type of volcano has gentle slopes and erupts with fluid lava?',
    amountOfLearners: 9,
    learnersPercent: 66,
    options: [
      {
        id: '1',
        option: 'Cinder Cone',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '2',
        option: 'Composite Volcano',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '3',
        option: 'Shield Volcano',
        isCorrect: true,
        amountOfLearners: 7,
        learnersPercent: 77,
      },
      {
        id: '4',
        option: 'Lava Dome',
        isCorrect: false,
        amountOfLearners: 0,
        learnersPercent: 0,
      },
    ],
  },
  {
    id: '5',
    question:
      'What is the name of the molten rock beneath the Earth’s surface?',
    amountOfLearners: 9,
    learnersPercent: 88,
    options: [
      {
        id: '1',
        option: 'Lava',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '2',
        option: 'Ash',
        isCorrect: false,
        amountOfLearners: 1,
        learnersPercent: 11,
      },
      {
        id: '3',
        option: 'Magma',
        isCorrect: true,
        amountOfLearners: 7,
        learnersPercent: 77,
      },
      {
        id: '4',
        option: 'Sill',
        isCorrect: false,
        amountOfLearners: 0,
        learnersPercent: 0,
      },
    ],
  },
];
