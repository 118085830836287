import React from 'react';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {analyticsText} from '../../analyticsText';
import './styles.css';

const options = (isEmpty: boolean): ApexOptions => ({
  legend: {
    show: false,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  chart: {
    height: '100%',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '80%',
        labels: {
          total: {
            show: false,
          },
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
  },
  fill: {
    colors: isEmpty ? ['#CED2D6'] : ['#53B483', '#3B82F6', '#CED2D6'],
  },
  stroke: {
    lineCap: 'butt',
    width: 3,
  },
  labels: ['', '', ''],
});

interface Props {
  completed: number;
  inProgress: number;
  notStarted: number;
  label: string;
}

const OverviewGraph = React.memo(
  ({completed, inProgress, notStarted, label}: Props) => {
    return (
      <div className="CoursesChartContainer">
        <div className="CoursesChart">
          <Chart
            options={options(completed + inProgress + notStarted === 0)}
            series={
              completed + inProgress + notStarted === 0
                ? [1]
                : [completed, inProgress, notStarted]
            }
            type="donut"
            width={140}
            height={140}
          />
          <div className="TotalCourses">
            <span>{label}</span>
            <p>{completed + inProgress + notStarted}</p>
          </div>
        </div>
        <div className="CoursesChartLegend">
          <span>{analyticsText.coursesOverview}</span>
          <table>
            <tr>
              <td>
                <div
                  className="LegendIndicator"
                  style={{backgroundColor: '#53B483'}}
                />
              </td>
              <td>
                <span>{analyticsText.completed}</span>
              </td>
              <td className="Value">
                <span>{completed}</span>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="LegendIndicator"
                  style={{backgroundColor: '#3B82F6'}}
                />
              </td>
              <td>
                <span>{analyticsText.inProgress}</span>
              </td>
              <td className="Value">
                <span>{inProgress}</span>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  className="LegendIndicator"
                  style={{backgroundColor: 'var(--neutral-color-8)'}}
                />
              </td>
              <td>
                <span>{analyticsText.notStarted}</span>
              </td>
              <td className="Value">
                <span>{notStarted}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  },
);

export default OverviewGraph;
