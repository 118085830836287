import React from 'react';
import {lessonAnalyticsQuestionsMockText} from '../lessonAnalyticsQuestionsMockText';

interface Props {
  title: string;
  value: string;
  amountOfLearners: number;
  learnersPercent: number;
  color: string;
}

const LessonGeneralStatCard = React.memo(
  ({title, value, amountOfLearners, learnersPercent, color}: Props) => {
    return (
      <div
        className="LessonStatCard"
        style={{
          borderLeft: `8px solid ${color}`,
        }}>
        <div>
          <p className="StatTitle">{title}</p>
          <span className="StatValue">{value}</span>
        </div>
        <div className="StatsFooter">
          <p className="StatTitle">
            {lessonAnalyticsQuestionsMockText.pickedFirst}
          </p>
          <div>
            <span className="StatFooterValue">
              {amountOfLearners}
              <span className="icon-users StatFooterValueIcon"></span>
            </span>
            <span className="StatFooterValue">
              {learnersPercent}
              <span className="StatFooterValueIcon">%</span>
            </span>
          </div>
        </div>
      </div>
    );
  },
);

export default LessonGeneralStatCard;
