import React from 'react';
import {dashboardText} from '../dashboardText';
import Button from '../../../uiToolkit/Buttons/Button';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {truncateNumberTo2Decimals} from '../../../utils/getTimeTextFromSeconds';
import ColorsManager from '../../../data/storage/ColorManager';

interface Props {
  onBuyAddons: () => void;
  storageUsed: number;
  storageAvailable: number;
}

const options = (storageAvailable: number): ApexOptions => ({
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 135,
      track: {
        background: '#CED2D6',
        startAngle: -135,
        endAngle: 135,
        margin: 5,
      },
      dataLabels: {
        name: {
          offsetY: -10,
          color: '#383F45',
          fontSize: '14px',
          fontFamily: 'var(--font-family)',
          fontWeight: '400',
        },
        value: {
          offsetY: -1,
          color: '#000',
          fontSize: '16px',
          show: true,
          fontWeight: '700',
          fontFamily: 'var(--font-family)',
          formatter: () => storageAvailable + ' GB',
        },
      },
    },
  },
  fill: {
    colors: [ColorsManager.getAccentColor(), '#CED2D6'],
  },
  stroke: {
    lineCap: 'butt',
  },
  labels: ['Remaining'],
});

const StorageCard = React.memo(
  ({onBuyAddons, storageUsed, storageAvailable}: Props) => {
    return (
      <div className="DashboardCard">
        <div className="d-flex flex-row justify-content-between">
          <p className="Title">{dashboardText.spaceQuota}</p>
          <h3 className="StorageAvailable">{storageAvailable / 1000} GB</h3>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <Chart
            options={options(
              truncateNumberTo2Decimals(
                (storageAvailable - storageUsed) / 1000,
              ),
            )}
            series={[
              ((storageAvailable - storageUsed) * 100) / storageAvailable,
            ]}
            type="radialBar"
            width={300}
            height={220}
          />
        </div>
        <div className="d-flex flex-row justify-content-center mt-4">
          <Button
            uiType="hollow"
            title={dashboardText.buyMoreSpace}
            onClick={onBuyAddons}
            icon="icon-icon_plus_circle"
          />
        </div>
      </div>
    );
  },
);

export default StorageCard;
