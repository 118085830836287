export const analyticsText = {
  title: 'Analytics',
  courses: 'Courses',
  coursesOverview: 'Courses Overview',
  completed: 'Completed',
  inProgress: 'In Progress',
  notStarted: 'Not started',
  passed: 'Passed',
  failed: 'Failed',
  avgTime: 'Average training time',
  avgScore: 'Average score',
  avgScoreHeader: 'Avg. score',
  selectCourse: 'Select course...',
  unassigned: 'Unassigned',
  export: 'Export',
  sortBy: 'Sort by Name (A-Z)',
  overallScore: 'Overall score',
  noData: 'It looks like you’re just getting started.',
  noDataInfo1: 'As learners interact with your content,',
  noDataInfo2:
    'this page will fill with performance stats and engagement data.',
  learner: 'Learner',
  status: 'Status',
  duration: 'Duration',
  score: 'Score',
  progress: 'Progress',
  analytics: 'Analytics',
  back: 'Back',
  passingScore: 'Passing score',
  learners: 'Learners',
  lesson: 'Lesson',
};
