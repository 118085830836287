import React, {useState} from 'react';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {lessonAnalyticsBranchesMockText} from '../lessonAnalyticsBranchesMockText';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import './styles.css';
import {MOCKED_LESSON_BRANCHES, mockLessonStatCards} from '../mockConf';
import LessonGeneralStatCard from '../components/LessonStatCard';
import Button from '../../../../uiToolkit/Buttons/Button';
import BranchDetails from '../components/BranchDetails';

const courseOptions = [
  {
    key: 'test',
    value: 'Course Name',
  },
];

interface Props {
  navigateToQuestions: () => void;
}

const LessonAnalyticsBranchesUI = React.memo(({navigateToQuestions}: Props) => {
  const [selectedCourse, setSelectedCourse] = useState('test');
  return (
    <div className="LessonAnalyticsBranchesContainer">
      <div className="Header">
        <PageTitle title={lessonAnalyticsBranchesMockText.title} />
        <Dropdown
          options={courseOptions}
          selectedOption={selectedCourse}
          setSelectedOption={setSelectedCourse}
          className="Dropdown"
        />
      </div>
      <div className="StatsCardsContainer">
        {mockLessonStatCards.map(item => (
          <LessonGeneralStatCard {...item} key={item.color} />
        ))}
      </div>
      <div className="ButtonTabsContainer">
        <Button
          uiType="hollow"
          onClick={navigateToQuestions}
          title={lessonAnalyticsBranchesMockText.questions}
        />
        <Button
          uiType="hollow"
          onClick={() => {
            //TODO
          }}
          title={lessonAnalyticsBranchesMockText.branches}
          className="ButtonActive"
        />
      </div>
      <div className="BranchesList">
        {MOCKED_LESSON_BRANCHES.map(item => (
          <BranchDetails key={item.id} branchDetails={item} />
        ))}
      </div>
    </div>
  );
});

export default LessonAnalyticsBranchesUI;
