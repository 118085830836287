import React from 'react';
import {analyticsEmptyState} from '../../../../assets/images';
import {analyticsText} from '../../analyticsText';
import './styles.css';

const AnalyticsEmptyState = React.memo(() => {
  return (
    <div className="AnalyticsEmptyState">
      <img src={analyticsEmptyState} />
      <span className="NoData">{analyticsText.noData}</span>
      <span>{analyticsText.noDataInfo1}</span>
      <span>{analyticsText.noDataInfo2}</span>
    </div>
  );
});

export default AnalyticsEmptyState;
