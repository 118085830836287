import AILessonGeneratorStore from './modules/AILessonGenerator/store/AILessonGeneratorStore';
import CookiePromptStore from './modules/CookiePrompt/store/CookiePromptStore';
import CourseAccessGateStore from './modules/CourseAccessGate/store/CourseAccessGateStore';
import CreateOrganizationStore from './modules/CreateOrganization/store/CreateOrganizationStore';
import AddAcknowledgmentWizardStore from './modules/Editor/AddAcknowledgmentWizard/store/AddAcknowledgmentWizardStore';
import AddChallengeBranchAIStore from './modules/Editor/AddChallengeBranchAIWizard/store/AddChallengeBranchAIStore';
import AddChallengeBranchQuestionStore from './modules/Editor/AddChallengeBranchQuestionWizard/store/AddChallengeBranchQuestionStore';
import AddChallengeBranchTextOptionStore from './modules/Editor/AddChallengeBranchTextOptionWizard/store/AddChallengeBranchTextOptionStore';
import AddChoiceQuestionStore from './modules/Editor/AddChoiceQuestionWizard/store/AddChoiceQuestionStore';
import AddChoiceTextOptionWizardStore from './modules/Editor/AddChoiceTextOptionWizard/store/AddChoiceTextOptionWizardStore';
import AddFreeformChoiceStore from './modules/Editor/AddFreeformChoiceOption/store/AddFreeformChoiceStore';
import AddImageNodeWizardStore from './modules/Editor/AddImageNodeWizard/store/AddImageNodeWizardStore';
import AddJumpToWizardStore from './modules/Editor/AddJumpToWizard/store/AddJumpToWizardStore';
import AddMChoiceWizardStore from './modules/Editor/AddMChoiceWizard/store/AddMChoiceWizardStore';
import AddVideoOverlayStore from './modules/Editor/AddVideoOverlayWizard/store/AddVideoOverlayStore';
import EditVideoOverlayStore from './modules/Editor/AddVideoOverlayWizard/store/EditVideoOverlayStore';
import AddVideoSourceWizardStore from './modules/Editor/AddVideoSourceWizard/store/AddVideoSourceWizardStore';
import SidebarNavigationStore from './modules/Editor/SidebarNavigation/store/SidebarNavigationStore';
import LearningJourneyStore from './modules/LearningJourney/store/LearningJourneyStore';
import ModalProviderStore from './modules/ModalProvider/store/ModalProviderStore';
import MyLibraryModalStore from './modules/MyLibraryModal/store/MyLibraryModalStore';
import NotificationCentreStore from './modules/NotificationCentre/store/NotificationCentreStore';
import PermissionGateStore from './modules/PermissionGate/store/PermissionGateStore';
import UserMenuPopUpStore from './modules/UserMenuPopUp/store/UserMenuPopUpStore';
import CourseEditRootStore from './navigation/rootElements/CourseEditRoot/store/CourseEditRootStore';
import NavigationStore from './navigation/store/NavigationStore';
import AdminPanelStore from './pages/AdminPanel/store/AdminPanelStore';
import ConfirmEmailStore from './pages/Auth/ConfirmEmail/store/ConfirmEmailStore';
import GeneralCourseEditStore from './pages/CourseDetails/CourseEdit/store/GeneralCourseEditStore';
import CourseInstructorStore from './pages/CourseDetails/CourseInstructors/store/CourseInstructorStore';
import CourseLearnerStore from './pages/CourseDetails/CourseLearners/store/CourseLearnerStore';
import CourseHomeStore from './pages/CourseHome/store/CourseHomeStore';
import CreateCourseStore from './pages/Courses/Create/store/CreateCourseStore';
import ListCoursesStore from './pages/Courses/List/store/ListCoursesStore';
import CoursePreviewStore from './pages/Courses/Preview/store/CoursePreviewStore';
import EditVideoSourceStore from './pages/EditVideoSource/store/EditVideoSourceStore';
import ExploreStore from './pages/Explore/store/ExplorePageStore';
import FoldersStore from './pages/Folders/store/FoldersStore';
import ForgotPasswordStore from './pages/Auth/ForgotPassword/store/ForgotPasswordStore';
import HomeStore from './pages/Home/store/HomeStore';
import LearnersStore from './pages/Learners/store/LearnersStore';
import LessonEditorStore from './pages/LessonEditor/store/LessonEditorStore';
import LessonsStore from './pages/Lessons/store/LessonsStore';
import VideoSourcesStore from './pages/Library/Root/store/VideoSourcesStore';
import LoginStore from './pages/Auth/Login/store/LoginStore';
import LTIPlayerStore from './pages/Integrations/LTIPlayer/store/LTIPlayerStore';
import ScormStore from './pages/Integrations/SCORMPlayer/store/ScormStore';
import LtiSettingsStore from './pages/Settings/LtiSettings/store/LtiSettingsStore';
import StorageIntegrationStore from './pages/Settings/StorageIntergrationSettings/store/StorageIntegrationStore';
import OrganizationStaffStore from './pages/Settings/store/OrganizationStaffStore';
import SettingsStore from './pages/Settings/store/SettingsStore';
import SubscriptionSettingsStore from './pages/Settings/SubscriptionSettings/store/SubscriptionSettingsStore';
import SignupStore from './pages/Auth/Signup/store/SignupStore';
import SecurityUserProfileStore from './pages/UserProfile/SecurityUserProfile/store/SecurityUserProfileStore';
import SettingsUserProfileStore from './pages/UserProfile/SettingsUserProfile/store/SettingsUserProfileStore';
import VideoPlaybackStore from './pages/VideoPlayback/store/VideoPlaybackStore';
import WorkspaceStore from './pages/Workspace/store/WorkspaceStore';
import UserInfoStore from './stores/UserInfoStore';
import SelectSubcsriptionStore from './pages/Subscription/SelectSubscription/store/SelectSubscriptionStore';
import SubcsriptionFirstPaymentStore from './pages/Subscription/SubscriptionFirstPayment/store/SubscriptionFirstPaymentStore';
import MarketplaceStore from './pages/Subscription/Marketplace/store/MarketplaceStore';
import GenerateAIVideosStore from './pages/TapyblAI/GenerateAIVideo/store/GenerateAIVideosStore';
import GenerateAiAssistantStore from './pages/TapyblAI/GenerateAIAssistant/store/GenerateAiAssistantStore';
import AiAssistantChatbotStore from './modules/AiAssistantChatbot/store/AiAssistantChatbotStore';
import AiAssistantStore from './pages/TapyblAI/AIAssistant/store/AiAssistnatStore';
import LearnerAiAssistantsStore from './modules/LearnerAiAssistants/store/LearnerAiAssistantsStore';
import AnalyticsStore from './pages/Analytics/GeneralCoursesAnalytics/store/AnalyticsStore';
import CourseAnalyticsLearnersStore from './pages/Analytics/CourseAnalyticsLearners/store/CourseAnalyticsLearnersStore';
import CreateNewCourseStore from './modules/CreateNewCourse/store/CreateNewCourseStore';
import EditCurrentCourseStore from './modules/EditCurrentCourse/store/EditCurrentCourseStore';

class StoresProvider {
  private navigationStore = new NavigationStore();
  private loginStore = new LoginStore();
  private adminPanelStore = new AdminPanelStore();
  private videoPlaybackStore = new VideoPlaybackStore();
  private videoSourcesStore = new VideoSourcesStore();
  private learnersStore = new LearnersStore();
  private signupStore = new SignupStore();
  private confirmEmailStore = new ConfirmEmailStore();
  private forgotPasswordStore = new ForgotPasswordStore();
  private createCourseStore = new CreateCourseStore();
  private createNewCourseStore = new CreateNewCourseStore();
  private userMenuPopUpStore = new UserMenuPopUpStore();
  private createOrganizationStore = new CreateOrganizationStore();
  private homeStore = new HomeStore();
  private listCoursesStore = new ListCoursesStore();
  private exploreStore = new ExploreStore();
  private settingsStore = new SettingsStore();
  private modalProviderStore = new ModalProviderStore();
  private organizationStaffStore = new OrganizationStaffStore();
  private userInfoStore = new UserInfoStore();
  private settingsUserProfileStore = new SettingsUserProfileStore();
  private foldersStore = new FoldersStore();
  private lessonsStore = new LessonsStore();
  private addVideoSourceWizardStore = new AddVideoSourceWizardStore();
  private addChoiceQuestionStore = new AddChoiceQuestionStore();
  private addChoiceTextOptionWizardStore = new AddChoiceTextOptionWizardStore();
  private lessonEditorStore = new LessonEditorStore();
  private myLibraryModalStore = new MyLibraryModalStore();
  private addMChoiceWizardStore = new AddMChoiceWizardStore();
  private courseHomeStore = new CourseHomeStore();
  private notificationCentreStore = new NotificationCentreStore();
  private generalCourseEditStore = new GeneralCourseEditStore();
  private editCurrentCourseStore = new EditCurrentCourseStore();
  private coursePreviewStore = new CoursePreviewStore();
  private addChallengeBranchQuestionStore =
    new AddChallengeBranchQuestionStore();
  private addChallengeBranchTextOptionStore =
    new AddChallengeBranchTextOptionStore();
  private addChallengeBranchAIStore = new AddChallengeBranchAIStore();
  private ltiPlayerStore = new LTIPlayerStore();
  private ltiSettingsStore = new LtiSettingsStore();
  private subscriptionSettingsStore = new SubscriptionSettingsStore();
  private workspaceStore = new WorkspaceStore();
  private courseAccessGateStore = new CourseAccessGateStore();
  private storageIntegrationStore = new StorageIntegrationStore();
  private addVideoOverlayStore = new AddVideoOverlayStore();
  private addFreeformChoiceStore = new AddFreeformChoiceStore();
  private addJumpToWizardStore = new AddJumpToWizardStore();
  private generateAIVideosStore = new GenerateAIVideosStore();
  private scormStore = new ScormStore();
  private cookiePromptStore = new CookiePromptStore();
  private editVideoOverlayStore = new EditVideoOverlayStore();
  private permissionGateStore = new PermissionGateStore();
  private addAcknowledgmentWizardStore = new AddAcknowledgmentWizardStore();
  private learningJourneyStore = new LearningJourneyStore();
  private addImageNodeWizardStore = new AddImageNodeWizardStore();
  private aiLessonGeneratorStore = new AILessonGeneratorStore();
  private securityUserProfileStore = new SecurityUserProfileStore();
  private editVideoSourceStore = new EditVideoSourceStore();
  private courseLearnerStore = new CourseLearnerStore();
  private courseInstructorStore = new CourseInstructorStore();
  private courseEditRootStore = new CourseEditRootStore();
  private sidebarNavigationStore = new SidebarNavigationStore();
  private selectSubcsriptionStore = new SelectSubcsriptionStore();
  private subcsriptionFirstPaymentStore = new SubcsriptionFirstPaymentStore();
  private marketplaceStore = new MarketplaceStore();
  private generateAiAssistantStore = new GenerateAiAssistantStore();
  private aiAssistantChatbotStore = new AiAssistantChatbotStore();
  private aiAssistantStore = new AiAssistantStore();
  private learnerAiAssistantsStore = new LearnerAiAssistantsStore();
  private analyticsStore = new AnalyticsStore();
  private courseAnalyticsLearnersStore = new CourseAnalyticsLearnersStore();

  public reInitStores() {
    this.navigationStore = new NavigationStore();
    this.loginStore = new LoginStore();
    this.adminPanelStore = new AdminPanelStore();
    this.videoPlaybackStore = new VideoPlaybackStore();
    this.videoSourcesStore = new VideoSourcesStore();
    this.learnersStore = new LearnersStore();
    this.signupStore = new SignupStore();
    this.confirmEmailStore = new ConfirmEmailStore();
    this.forgotPasswordStore = new ForgotPasswordStore();
    this.createCourseStore = new CreateCourseStore();
    this.createNewCourseStore = new CreateNewCourseStore();
    this.userMenuPopUpStore = new UserMenuPopUpStore();
    this.createOrganizationStore = new CreateOrganizationStore();
    this.homeStore = new HomeStore();
    this.listCoursesStore = new ListCoursesStore();
    this.exploreStore = new ExploreStore();
    this.settingsStore = new SettingsStore();
    this.modalProviderStore = new ModalProviderStore();
    this.organizationStaffStore = new OrganizationStaffStore();
    this.userInfoStore = new UserInfoStore();
    this.settingsUserProfileStore = new SettingsUserProfileStore();
    this.foldersStore = new FoldersStore();
    this.lessonsStore = new LessonsStore();
    this.addVideoSourceWizardStore = new AddVideoSourceWizardStore();
    this.addChoiceQuestionStore = new AddChoiceQuestionStore();
    this.addChoiceTextOptionWizardStore = new AddChoiceTextOptionWizardStore();
    this.lessonEditorStore = new LessonEditorStore();
    this.learnerAiAssistantsStore = new LearnerAiAssistantsStore();
    this.myLibraryModalStore = new MyLibraryModalStore();
    this.addMChoiceWizardStore = new AddMChoiceWizardStore();
    this.courseHomeStore = new CourseHomeStore();
    this.notificationCentreStore = new NotificationCentreStore();
    this.generalCourseEditStore = new GeneralCourseEditStore();
    this.editCurrentCourseStore = new EditCurrentCourseStore();
    this.coursePreviewStore = new CoursePreviewStore();
    this.addChallengeBranchQuestionStore =
      new AddChallengeBranchQuestionStore();
    this.addChallengeBranchTextOptionStore =
      new AddChallengeBranchTextOptionStore();
    this.addChallengeBranchAIStore = new AddChallengeBranchAIStore();
    this.ltiPlayerStore = new LTIPlayerStore();
    this.ltiSettingsStore = new LtiSettingsStore();
    this.subscriptionSettingsStore = new SubscriptionSettingsStore();
    this.workspaceStore = new WorkspaceStore();
    this.generateAiAssistantStore = new GenerateAiAssistantStore();
    this.courseAccessGateStore = new CourseAccessGateStore();
    this.storageIntegrationStore = new StorageIntegrationStore();
    this.addVideoOverlayStore = new AddVideoOverlayStore();
    this.addFreeformChoiceStore = new AddFreeformChoiceStore();
    this.addJumpToWizardStore = new AddJumpToWizardStore();
    this.generateAIVideosStore = new GenerateAIVideosStore();
    this.scormStore = new ScormStore();
    this.cookiePromptStore = new CookiePromptStore();
    this.editVideoOverlayStore = new EditVideoOverlayStore();
    this.permissionGateStore = new PermissionGateStore();
    this.addAcknowledgmentWizardStore = new AddAcknowledgmentWizardStore();
    this.learningJourneyStore = new LearningJourneyStore();
    this.addImageNodeWizardStore = new AddImageNodeWizardStore();
    this.aiLessonGeneratorStore = new AILessonGeneratorStore();
    this.securityUserProfileStore = new SecurityUserProfileStore();
    this.editVideoSourceStore = new EditVideoSourceStore();
    this.courseLearnerStore = new CourseLearnerStore();
    this.courseInstructorStore = new CourseInstructorStore();
    this.courseEditRootStore = new CourseEditRootStore();
    this.sidebarNavigationStore = new SidebarNavigationStore();
    this.selectSubcsriptionStore = new SelectSubcsriptionStore();
    this.subcsriptionFirstPaymentStore = new SubcsriptionFirstPaymentStore();
    this.marketplaceStore = new MarketplaceStore();
    this.aiAssistantChatbotStore = new AiAssistantChatbotStore();
    this.aiAssistantStore = new AiAssistantStore();
    this.analyticsStore = new AnalyticsStore();
    this.courseAnalyticsLearnersStore = new CourseAnalyticsLearnersStore();
  }

  public getNavigationStore() {
    return this.navigationStore;
  }

  public getStores() {
    return {
      navigationStore: this.navigationStore,
      loginStore: this.loginStore,
      adminPanelStore: this.adminPanelStore,
      videoPlaybackStore: this.videoPlaybackStore,
      videoSourcesStore: this.videoSourcesStore,
      learnersStore: this.learnersStore,
      signupStore: this.signupStore,
      confirmEmailStore: this.confirmEmailStore,
      forgotPasswordStore: this.forgotPasswordStore,
      createCourseStore: this.createCourseStore,
      createNewCourseStore: this.createNewCourseStore,
      userMenuPopUpStore: this.userMenuPopUpStore,
      createOrganizationStore: this.createOrganizationStore,
      homeStore: this.homeStore,
      listCoursesStore: this.listCoursesStore,
      exploreStore: this.exploreStore,
      settingsStore: this.settingsStore,
      modalProviderStore: this.modalProviderStore,
      organizationStaffStore: this.organizationStaffStore,
      userInfoStore: this.userInfoStore,
      settingsUserProfileStore: this.settingsUserProfileStore,
      foldersStore: this.foldersStore,
      lessonsStore: this.lessonsStore,
      addVideoSourceWizardStore: this.addVideoSourceWizardStore,
      addChoiceQuestionStore: this.addChoiceQuestionStore,
      addChoiceTextOptionWizardStore: this.addChoiceTextOptionWizardStore,
      lessonEditorStore: this.lessonEditorStore,
      myLibraryModalStore: this.myLibraryModalStore,
      addMChoiceWizardStore: this.addMChoiceWizardStore,
      courseHomeStore: this.courseHomeStore,
      notificationCentreStore: this.notificationCentreStore,
      generalCourseEditStore: this.generalCourseEditStore,
      editCurrentCourseStore: this.editCurrentCourseStore,
      coursePreviewStore: this.coursePreviewStore,
      addChallengeBranchQuestionStore: this.addChallengeBranchQuestionStore,
      addChallengeBranchTextOptionStore: this.addChallengeBranchTextOptionStore,
      addChallengeBranchAIStore: this.addChallengeBranchAIStore,
      ltiPlayerStore: this.ltiPlayerStore,
      ltiSettingsStore: this.ltiSettingsStore,
      subscriptionSettingsStore: this.subscriptionSettingsStore,
      workspaceStore: this.workspaceStore,
      courseAccessGateStore: this.courseAccessGateStore,
      storageIntegrationStore: this.storageIntegrationStore,
      addVideoOverlayStore: this.addVideoOverlayStore,
      addFreeformChoiceStore: this.addFreeformChoiceStore,
      addJumpToWizardStore: this.addJumpToWizardStore,
      generateAIVideosStore: this.generateAIVideosStore,
      scormStore: this.scormStore,
      cookiePromptStore: this.cookiePromptStore,
      editVideoOverlayStore: this.editVideoOverlayStore,
      permissionGateStore: this.permissionGateStore,
      addAcknowledgmentWizardStore: this.addAcknowledgmentWizardStore,
      learningJourneyStore: this.learningJourneyStore,
      addImageNodeWizardStore: this.addImageNodeWizardStore,
      aiLessonGeneratorStore: this.aiLessonGeneratorStore,
      securityUserProfileStore: this.securityUserProfileStore,
      editVideoSourceStore: this.editVideoSourceStore,
      courseLearnerStore: this.courseLearnerStore,
      courseInstructorStore: this.courseInstructorStore,
      courseEditRootStore: this.courseEditRootStore,
      sidebarNavigationStore: this.sidebarNavigationStore,
      selectSubcsriptionStore: this.selectSubcsriptionStore,
      subcsriptionFirstPaymentStore: this.subcsriptionFirstPaymentStore,
      marketplaceStore: this.marketplaceStore,
      generateAiAssistantStore: this.generateAiAssistantStore,
      aiAssistantChatbotStore: this.aiAssistantChatbotStore,
      aiAssistantStore: this.aiAssistantStore,
      learnerAiAssistantsStore: this.learnerAiAssistantsStore,
      analyticsStore: this.analyticsStore,
      courseAnalyticsLearnersStore: this.courseAnalyticsLearnersStore,
    };
  }
}

export default StoresProvider;
