import React, {useState} from 'react';
import PageTitle from '../../../../uiToolkit/PageTitle';
import {lessonAnalyticsQuestionsMockText} from '../lessonAnalyticsQuestionsMockText';
import Dropdown from '../../../../uiToolkit/Inputs/Dropdown';
import './styles.css';
import {MOCKED_LESSON_QUESTIONS, mockLessonStatCards} from '../mockConf';
import LessonGeneralStatCard from '../components/LessonStatCard';
import Button from '../../../../uiToolkit/Buttons/Button';
import QuestionDetails from '../components/QuestionDetails';

const courseOptions = [
  {
    key: 'test',
    value: 'Course Name',
  },
];

interface Props {
  navigateToBranches: () => void;
}

const LessonAnalyticsQuestionsUI = React.memo(({navigateToBranches}: Props) => {
  const [selectedCourse, setSelectedCourse] = useState('test');
  return (
    <div className="LessonAnalyticsQuestionsContainer">
      <div className="Header">
        <PageTitle title={lessonAnalyticsQuestionsMockText.title} />
        <Dropdown
          options={courseOptions}
          selectedOption={selectedCourse}
          setSelectedOption={setSelectedCourse}
          className="Dropdown"
        />
      </div>
      <div className="StatsCardsContainer">
        {mockLessonStatCards.map(item => (
          <LessonGeneralStatCard {...item} key={item.color} />
        ))}
      </div>
      <div className="ButtonTabsContainer">
        <Button
          uiType="hollow"
          onClick={() => {
            //TODO
          }}
          title={lessonAnalyticsQuestionsMockText.questions}
          className="ButtonActive"
        />
        <Button
          uiType="hollow"
          onClick={navigateToBranches}
          title={lessonAnalyticsQuestionsMockText.branches}
        />
      </div>
      <div className="QuestionsList">
        {MOCKED_LESSON_QUESTIONS.map((item, index) => (
          <QuestionDetails
            key={item.id}
            questionDetails={item}
            index={index + 1}
          />
        ))}
      </div>
    </div>
  );
});

export default LessonAnalyticsQuestionsUI;
