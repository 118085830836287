import {inject, observer} from 'mobx-react';
import React from 'react';
import AILessonGeneratorUI from '../view/AILessonGeneratorUI';
import AILessonGeneratorStore from '../store/AILessonGeneratorStore';
import {useParams} from 'react-router-dom';
import MyLibraryModalStore from '../../MyLibraryModal/store/MyLibraryModalStore';
import {MEDIA_FILES_ALL_VIDEO} from '../../../data/models/LibraryFile/MediaFile';

interface Props {
  aiLessonGeneratorStore?: AILessonGeneratorStore;
  myLibraryModalStore?: MyLibraryModalStore;
}

const AILessonGeneratorContainer = inject(
  'aiLessonGeneratorStore',
  'myLibraryModalStore',
)(
  observer(({aiLessonGeneratorStore, myLibraryModalStore}: Props) => {
    const {organizationId} = useParams();
    const onClose = () => {
      aiLessonGeneratorStore?.closeWizard();
    };

    const generateLesson = (
      includeBranching: boolean,
      includeGrading: boolean,
      passingScore?: number,
    ) => {
      if (organizationId) {
        aiLessonGeneratorStore!.generateLesson(
          organizationId,
          includeBranching,
          includeGrading,
          passingScore,
        );
      }
    };

    const onRemoveFile = () => {
      aiLessonGeneratorStore!.clearSourceFile();
    };

    const onBrowseFiles = () => {
      myLibraryModalStore!.showLibrary(
        MEDIA_FILES_ALL_VIDEO,
        files => (aiLessonGeneratorStore!.sourceFile = files[0]),
        false,
        aiLessonGeneratorStore!.sourceFile
          ? [aiLessonGeneratorStore!.sourceFile]
          : [],
      );
    };

    return aiLessonGeneratorStore!.showModal ? (
      <AILessonGeneratorUI
        onClose={onClose}
        isGradable={aiLessonGeneratorStore!.getIsGradable()}
        videoFile={aiLessonGeneratorStore!.sourceFile}
        generateLesson={generateLesson}
        isLoading={aiLessonGeneratorStore!.isLoading}
        onBrowseFiles={onBrowseFiles}
        onRemoveFile={onRemoveFile}
      />
    ) : null;
  }),
);

export default AILessonGeneratorContainer;
