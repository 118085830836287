import React from 'react';
import {dashboardText} from '../dashboardText';

interface Props {
  allCourses: number;
  publishedCourses: number;
}

const CoursesCard = React.memo(({allCourses, publishedCourses}: Props) => {
  return (
    <div className="DashboardCard">
      <p className="Title">{dashboardText.courses}</p>
      <div className="CoursesCardBody">
        <div className="d-flex flex-column align-items-center">
          <p className="AiCredits">{allCourses}</p>
          <span className="Label">{dashboardText.created}</span>
        </div>
        <div className="Divider" />
        <div className="d-flex flex-column align-items-center">
          <p className="AiCredits">{publishedCourses}</p>
          <span className="Label">{dashboardText.published}</span>
        </div>
      </div>
    </div>
  );
});

export default CoursesCard;
