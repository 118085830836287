import React, {useEffect, useState} from 'react';
import ModalContainer from '../../../uiToolkit/ModalContainer';
import {aiLessonGeneratorText} from '../aiLessonGeneratorText';
import './styles.css';
import NumbersInput from '../../../uiToolkit/Inputs/NumbersInput';
import Button from '../../../uiToolkit/Buttons/Button';
import {MediaFile} from '../../../data/models/LibraryFile/MediaFile';
import AILoader from '../../../uiToolkit/AILoader';
import LibraryFilesInput from '../../../uiToolkit/Inputs/LibraryFilesInput';
import ToggleSwitch from '../../../uiToolkit/Inputs/ToggleSwitch';
import {ASSESMENT_OPTIONS, BRANCHING_OPTIONS} from '../conf';

interface Props {
  onClose: () => void;
  videoFile?: MediaFile;
  generateLesson: (
    includeBranching: boolean,
    includeGrading: boolean,
    passingScore?: number,
  ) => void;
  isLoading: boolean;
  isGradable: boolean;
  onBrowseFiles: () => void;
  onRemoveFile: () => void;
}

const AILessonGeneratorUI = React.memo(
  ({
    onClose,
    videoFile,
    generateLesson,
    isLoading,
    isGradable,
    onRemoveFile,
    onBrowseFiles,
  }: Props) => {
    const [includeBranching, setIncludeBranching] = useState('0');
    const [includeGrading, setIncludeGrading] = useState('0');
    const [passingScore, setPassingScore] = useState<number | undefined>(50);

    useEffect(() => {
      setIncludeGrading(isGradable ? '1' : '0');
    }, [isGradable]);

    const onGenerate = () => {
      generateLesson(
        includeBranching === '1',
        includeGrading === '1',
        includeGrading ? passingScore : undefined,
      );
    };

    return (
      <ModalContainer onClose={onClose}>
        <div className="LessonGeneration">
          {isLoading ? (
            <AILoader />
          ) : (
            <>
              <div className="LessonGenHeading">
                <p>{aiLessonGeneratorText.title}</p>
              </div>
              <p className="LessonGenVideoTitle" style={{marginBottom: '20px'}}>
                {aiLessonGeneratorText.source}
              </p>
              <LibraryFilesInput
                files={videoFile ? [videoFile] : []}
                onRemoveFile={onRemoveFile}
                onBrowse={onBrowseFiles}
              />
              <p className="CustomizeTitle">
                {aiLessonGeneratorText.customizeLesson}
              </p>
              <p className="LessonGenVideoTitle">
                {aiLessonGeneratorText.includeBranching}
              </p>
              <p className="LessonGenVideoSubTitle">
                {aiLessonGeneratorText.branchingSubtitle}
              </p>
              <ToggleSwitch
                options={BRANCHING_OPTIONS}
                selectedKey={includeBranching}
                onChange={setIncludeBranching}
              />
              <p className="LessonGenVideoTitle">
                {aiLessonGeneratorText.includeGrading}
              </p>
              <p className="LessonGenVideoSubTitle">
                {aiLessonGeneratorText.assesmentSubtitle}
              </p>
              <ToggleSwitch
                options={ASSESMENT_OPTIONS}
                selectedKey={includeGrading}
                onChange={setIncludeGrading}
              />
              <p className="LessonGenVideoTitle">
                {aiLessonGeneratorText.passingScore}
              </p>
              <p className="LessonGenVideoSubTitle">
                {aiLessonGeneratorText.passingScoreSubtitle}
              </p>
              <NumbersInput
                value={passingScore}
                setValue={setPassingScore}
                disabled={!includeGrading}
                className="LessonGenPassingScore"
              />
              <div className="Footer">
                <Button
                  title={aiLessonGeneratorText.cancel}
                  onClick={onClose}
                  uiType="text"
                />
                <Button
                  title={aiLessonGeneratorText.generate}
                  onClick={onGenerate}
                />
              </div>
            </>
          )}
        </div>
      </ModalContainer>
    );
  },
);

export default AILessonGeneratorUI;
