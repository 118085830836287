import React, {useEffect} from 'react';
import WorkspaceUI from '../view/WorkspaceUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import WorkspaceStore from '../store/WorkspaceStore';
import {APP_PERMISSIONS} from '../../../data/permissions';
import NavigationStore from '../../../navigation/store/NavigationStore';
import {ORGANIZATION_SUBSCRIPTION_ACTIVE} from '../../../data/models/Oganization/Organization';

interface Props {
  workspaceStore?: WorkspaceStore;
  navigationStore?: NavigationStore;
}

const WorkspaceContainer = inject(
  'workspaceStore',
  'navigationStore',
)(
  observer(({workspaceStore, navigationStore}: Props) => {
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        workspaceStore!.fetchDashboardDetails(organizationId);
      }
    }, []);

    const onStartTrial = () => {
      navigate(`/subscription/${organizationId}/select_trial`);
    };

    const onSubscribe = () => {
      navigate(`/manage/${organizationId}/settings/subscription`);
    };

    const onBuyAddons = () => {
      navigate(`/${organizationId}/marketplace`);
    };

    const onCustomizeWorkspaceBranding = () => {
      navigate(`/manage/${organizationId}/settings/general`);
    };

    const onManageAccount = () => {
      navigate(`/manage/${organizationId}/settings/general`);
    };

    const onManageIntegrations = () => {
      navigate(`/manage/${organizationId}/settings/lti_settings`);
    };

    const onContactUs = () => {
      if (window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window
          .open(
            'https://help.tapybl.com/support/tickets/new?ticket_form=upgrade_plan',
            '_blank',
          )
          .focus();
      }
    };

    const onOpenAssistants = () => {
      navigate(`/manage/${organizationId}/ai/ai_assistant`);
    };

    return navigationStore!.userOrganization ? (
      <WorkspaceUI
        hasSubscription={
          navigationStore!.subscriptionStatus ===
          ORGANIZATION_SUBSCRIPTION_ACTIVE
        }
        usedTrial={
          navigationStore!.userOrganization.trialSubscriptionUsed || false
        }
        onOpenAssistants={onOpenAssistants}
        onBuyAddons={onBuyAddons}
        isLoading={workspaceStore!.isLoading}
        dashboardData={workspaceStore!.dashboardData}
        onSubscribe={onSubscribe}
        onStartTrial={onStartTrial}
        navigateCustomizeWorkspaceBranding={onCustomizeWorkspaceBranding}
        navigateManageAccount={onManageAccount}
        navigateManageIntegrations={onManageIntegrations}
        hasAdminPermissions={navigationStore!.userPermissions.includes(
          APP_PERMISSIONS.ORG_MANAGE_SETTINGS,
        )}
        organizationId={organizationId}
        onContactUs={onContactUs}
        subscriptionPermissions={navigationStore!.subscriptionPermissions}
      />
    ) : null;
  }),
);

export default WorkspaceContainer;
