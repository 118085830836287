import React from 'react';
import AppRouter from './navigation';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {Provider} from 'mobx-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts';
import {ToastContainer} from 'react-toastify';
import {createTheme} from 'react-data-table-component';
import {ModalProvider} from './modules/ModalProvider';
import StoresProvider from './storesProvider';
import 'new_tapybl_fonts';

createTheme('custom-stripes', {
  striped: {
    default: '#F2F2F2',
    text: 'rgba(0, 0, 0, 0.87)',
  },
});

const GOOGLE_CLIENT_ID =
  '904613567150-rn64nm7fs95f0o297upqnjd90vrpglut.apps.googleusercontent.com';

export const storesProvider = new StoresProvider();

function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Provider {...storesProvider.getStores()}>
        <AppRouter />
        <ModalProvider />
        <ToastContainer />
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
