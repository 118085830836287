import React from 'react';
import {dashboardText} from '../dashboardText';
import {subscriptionExpiredImage} from '../../../assets/images';
import Button from '../../../uiToolkit/Buttons/Button';
import PageTitle from '../../../uiToolkit/PageTitle';

interface Props {
  hasAdminPermissions: boolean;
  isLoading: boolean;
  onSubscribe: () => void;
}

const NoSubscriptionState = React.memo(
  ({hasAdminPermissions, onSubscribe, isLoading}: Props) => {
    return (
      <div className="mt-4">
        <PageTitle title={dashboardText.title} />
        {hasAdminPermissions ? (
          <div className="SubscriptionStartContainer">
            <img src={subscriptionExpiredImage} />
            <h1>{dashboardText.subcriptionEnded}</h1>
            <p>{dashboardText.subscriptionEndedInfo}</p>
            <p>{dashboardText.subscriptionEndedInfo2}</p>

            <>
              <span className="ClickToUpgrade">{dashboardText.clickToSub}</span>
              <Button
                title={dashboardText.upgradeNow}
                onClick={onSubscribe}
                isLoading={isLoading}
                className="StartTrialButton"
              />
            </>
          </div>
        ) : (
          <div className="SubscriptionStartContainer">
            <span>{dashboardText.subscriptionExpired}</span>
          </div>
        )}
      </div>
    );
  },
);

export default NoSubscriptionState;
